import { ReactNode, useState } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import { StaticImageData } from 'next/image'
import { Divider, Image, Modal, useMediaQuery } from '@pig-frontend/uikit'
import { CloseOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

interface IBank {
  key: string
  name: string
  img: StaticImageData
}

export interface IFooterProps {
  logo: StaticImageData
  secureEncryptIcon: StaticImageData
  banks: IBank[]
  copyright: {
    description: string
    copyright: string
  }
  legalContent: ReactNode
  haveNavbar?: boolean
  children?: ReactNode
  gitTagVersion?: string
}

export default function Footer({
  logo,
  secureEncryptIcon,
  banks,
  copyright,
  legalContent,
  haveNavbar = false,
  children,
  gitTagVersion = '0.0.0',
}: IFooterProps) {
  const isMD = useMediaQuery('md')
  const [legalModalVisible, setLegalModalVisible] = useState<boolean>(false)
  const extra = haveNavbar && !isMD

  return (
    <div className={cx(styles.wrapper, { [styles.extra]: extra })}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Image src={logo} alt="logo" className={styles.logo} />
          <span>V {gitTagVersion}</span>
        </div>

        <div className={styles['menus-container']}>
          <ul className={styles.menus}>
            <li>
              <Link href="/games/all">เกม</Link>
            </li>
            <li>
              <Link href="/promotions">โปรโมชั่น</Link>
            </li>
            <li>
              <Link href="/faq">ถาม-ตอบ</Link>
            </li>
            <li>
              <Link href="/contact-us">แจ้งปัญหาการใช้งาน</Link>
            </li>
            <li
              role="button"
              aria-hidden
              onClick={() => setLegalModalVisible(true)}
            >
              ข้อกำหนดและเงื่อนไข
            </li>
            <Modal
              open={legalModalVisible}
              closable={false}
              footer={false}
              className={styles['legal-modal']}
            >
              <div className={styles.header}>
                <span>ข้อกำหนดและเงื่อนไข</span>
                <CloseOutlined onClick={() => setLegalModalVisible(false)} />
              </div>
              <Divider margin={16} />
              <div className={styles.content}>
                <span>{legalContent}</span>
              </div>
            </Modal>
          </ul>

          <div className={styles.ssl}>
            <Image
              src={secureEncryptIcon}
              alt="secure SSL Encryption"
              className={styles.img}
            />
            <div className={styles.text}>
              <span>Secure</span>
              <span>SSL Encryption</span>
            </div>
          </div>

          {isMD && (
            <div className={styles.banks}>
              {banks.map((bank) => (
                <Image key={bank.key} src={bank.img} alt={bank.name} />
              ))}
            </div>
          )}
        </div>

        {!isMD && (
          <>
            <Divider className={styles.divider} margin={16} />
            <div className={styles.banks}>
              {banks.map((bank) => (
                <Image key={bank.key} src={bank.img} alt={bank.name} />
              ))}
            </div>
          </>
        )}

        {children && (
          <>
            <Divider className={styles.divider} margin={16} />
            {children}
          </>
        )}

        <Divider className={styles.divider} margin={16} />

        <div className={styles.description}>
          <p className={styles.text}>{copyright?.description}</p>
          <p className={styles.copyright}>{copyright?.copyright}</p>
          <br />
        </div>
      </div>
    </div>
  )
}
