import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { Layout } from '@pig-frontend/uikit'
import dayjs from 'dayjs'
import {
  logo2Image,
  footerSecureEncryptionImage,
} from '@pig-casino/constants/images'
//import { PartnerSection } from '@pig-casino/components/lobby'
import { GIT_TAG_VERSION } from '@pig-casino/constants/env'
import { banks } from './config/banks'
import LegalContent from './config/legal-content'
//import styles from './index.module.scss'

export default function Footer() {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)

  return (
    <Layout.Footer
      logo={logo2Image}
      secureEncryptIcon={footerSecureEncryptionImage}
      banks={banks}
      copyright={{
        copyright: `Copyright © ${dayjs().format(
          'YYYY',
        )} Pigbet (publ). All rights reserved.`,
        description:
          'สัญลักษณ์และรูปภาพต่างๆบนเว็บไซต์ PIGBET.app ถือเป็นทรัพย์สินทางปัญญา ห้ามมิให้มีการคัดลอก ดัดแปลง ทำซ้ำ แก้ไข แผยแพร่โดยมิได้รับการยินยอมเป็นลายลักษณ์อักษรจากทาง PIGBET',
      }}
      legalContent={<LegalContent />}
      haveNavbar={isAuthenticated}
      gitTagVersion={GIT_TAG_VERSION}
    >
      {/*
      <PartnerSection
        title={{ h6: 'พาร์ทเนอร์' }}
        // className={styles.partner}
        imageWrapperClassName={styles['image-container']}
        sectionClassName={styles.section}
        isWhiteImage
        blackListKey={['csrslots']}
      />
      */}
    </Layout.Footer>
  )
}
