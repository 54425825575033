import { pageLinkCommon } from '@pig-common/constants/page-name'

const pageLink = {
  game: '/game',
  games: {
    index: '/games/all',
    all: '/games/all',
    providers: '/games/providers',
    favorite: '/games/favorite',
    recent: '/games/recent',
    popular: '/games/popular',
  },
  seo: {
    bacara: '/บาคาร่า-เว็บตรง',
    roulette: '/รูเล็ต-เว็บตรง',
    highlow: '/ไฮโล-เว็บตรง',
    demoBacara: '/ทดลองเล่น-บาคาร่า',
  },
  contactUs: '/contact-us',
}

const extendedPageLink = { ...pageLink, ...pageLinkCommon }

export { extendedPageLink as pageLink }
