import { atom } from 'recoil'

import { KEYS } from '../keys'
import { GuildInfo } from '@pig-common/types/Guild.type'

export interface IGuildState {
  data: GuildInfo
}

export const initialValue: IGuildState | undefined = undefined

export const guildInfoState = atom<IGuildState | undefined>({
  key: KEYS.GUILD_INFO,
  default: initialValue,
})

export default guildInfoState
