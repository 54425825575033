import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

export interface IParams {
  userUID: string
}

export interface IBalanceResponse {
  userCode?: string
  transactionUid?: string
  currencyCode?: 'THB'
  balanceAmount?: number
  coinAmount?: number
}

async function get({
  userUID,
}: IParams): Promise<CommonResponse<IBalanceResponse>> {
  return await api.get(`/v1/wallet/${userUID}/balance`)
}

export const key = {
  get: 'USER_BALANCE',
}

export { get }
