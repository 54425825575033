import { selector } from 'recoil'
import {
  authStateCommon,
  inboxStateCommon,
  registerStateCommon,
  rankingGameStateCommon,
  onInputFocusStateCommon,
  systemStatusStateCommon,
  profileStateCommon,
  favoriteGameStateCommon,
  backButtonStateCommon,
  recentGameStateCommon,
  walletStateCommon,
  chatChannelStateCommon,
} from '@pig-common/recoils'

export const key = {
  resetAll: 'RESET_ALL',
}

export const resetAll = selector({
  key: key.resetAll,
  get: () => {},
  set: ({ reset }) => {
    reset(authStateCommon.authState)
    reset(registerStateCommon.registerState)
    reset(rankingGameStateCommon.rankingGameState)
    reset(onInputFocusStateCommon.onInputFocusState)
    reset(systemStatusStateCommon.systemStatusState)
    reset(profileStateCommon.profileState)
    reset(favoriteGameStateCommon.favoriteGameState)
    reset(backButtonStateCommon.backButtonState)
    reset(recentGameStateCommon.recentGameState)
    reset(walletStateCommon.walletSelectorState)
    reset(chatChannelStateCommon.chatChannelState)
    reset(inboxStateCommon.inboxSystemStatusState)
    reset(inboxStateCommon.inboxTransactionState)
    reset(inboxStateCommon.inboxBonusState)
    reset(inboxStateCommon.inboxAffiliateState)
  },
})
