import { CommonResponse } from '@pig-common/types/Api.type'
import { IChampionItem, ChampionParams } from '@pig-casino/types/Champion.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { AxiosRequestConfig } from 'axios'

async function list(
  params: ChampionParams,
): Promise<CommonResponse<IChampionItem[]>> {
  const paramsStr =
    params.page && params.limit
      ? `?${new URLSearchParams({
          page: `${params.page}`,
          limit: `${params.limit}`,
        }).toString()}`
      : ''
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/loyalty/casino/bigwin-stack${paramsStr}`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export const key = {
  list: 'LIST_CHAMPIONS',
}

export { list }
