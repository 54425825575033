import { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { notification } from '@pig-frontend/uikit'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'
import { authStateCommon, systemStatusStateCommon } from '@pig-common/recoils'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { camelizeKeys } from '@pig-frontend/utils'
import { ISystemStatusResponse } from '@pig-common/types/SystemStatus.type'

const notificationDuration = 0 // Permanent show

export default function useSystemStatusNotification() {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const {
    data: systemStatusRQ = {},
    isFetching: isSystemStatusLoading,
    refetch: getSystemStatus,
  } = useSystemStatus({ enabled: false })
  const systemStatusStateRecoil = useRecoilValue(
    systemStatusStateCommon.systemStatusState,
  )
  const systemStatusState = useMemo(() => {
    return camelizeKeys(systemStatusStateRecoil)
  }, [systemStatusStateRecoil])

  /**
   * @NOTE Due to key has a difference sensitive case,
   * and changing recoil state to camel case affects many components
   * If we have time we should change at root case, that is changing sensitive case
   */
  const getSystemValue = (key: keyof ISystemStatusResponse) => {
    return systemStatusRQ?.[key] || systemStatusState?.[key]
  }

  const showFreestyleTextNoti = useCallback(() => {
    if (getSystemValue('freeStyle')) {
      notification.info({
        message: parse(getSystemValue('freeStyleText')),
        duration: notificationDuration,
      })
    }
  }, [getSystemValue('freeStyle'), getSystemValue('freeStyleText')])

  useEffect(() => {
    showFreestyleTextNoti()
  }, [showFreestyleTextNoti])

  const showAppGameErrorTextNoti = useCallback(() => {
    if (getSystemValue('gameError')) {
      notification.info({
        key: 'gameError',
        message: parse(getSystemValue('gameErrorText')),
        duration: notificationDuration,
      })
    }
  }, [getSystemValue('gameError'), getSystemValue('gameErrorText')])

  useEffect(() => {
    showAppGameErrorTextNoti()
  }, [showAppGameErrorTextNoti])

  const showCloseRegister = useCallback(() => {
    if (getSystemValue('register') && !isAuthenticated) {
      notification.info({
        message: parse(getSystemValue('registerText')),
        duration: notificationDuration,
      })
    }
  }, [
    getSystemValue('register'),
    getSystemValue('registerText'),
    isAuthenticated,
  ])

  useEffect(() => {
    showCloseRegister()
  }, [showCloseRegister])

  const showCloseDeposit = useCallback(() => {
    if (getSystemValue('depositError') && isAuthenticated) {
      notification.info({
        message: parse(getSystemValue('depositErrorText')),
        duration: notificationDuration,
      })
    }
  }, [
    getSystemValue('depositError'),
    getSystemValue('depositErrorText'),
    isAuthenticated,
  ])

  useEffect(() => {
    showCloseDeposit()
  }, [showCloseDeposit])

  const showCloseWithdrawal = useCallback(() => {
    if (getSystemValue('withdrawError') && isAuthenticated) {
      notification.info({
        message: parse(getSystemValue('withdrawErrorText')),
        duration: notificationDuration,
      })
    }
  }, [
    getSystemValue('withdrawError'),
    getSystemValue('withdrawErrorText'),
    isAuthenticated,
  ])

  useEffect(() => {
    showCloseWithdrawal()
  }, [showCloseWithdrawal])

  const showFriendInvite = useCallback(() => {
    if (getSystemValue('friendInvite') && isAuthenticated) {
      notification.info({
        message: parse(getSystemValue('friendInviteText')),
        duration: notificationDuration,
      })
    }
  }, [
    getSystemValue('friendInvite'),
    getSystemValue('friendInviteText'),
    isAuthenticated,
  ])

  useEffect(() => {
    showFriendInvite()
  }, [showFriendInvite])

  /* -------- System Status -------- */
  useEffect(() => {
    // Refresh getSystemValue()page changing
    // Moved from middleware in order to avoid fetching before component will mount for web improving speed
    if (router.pathname !== pageLinkCommon.maintenance) {
      getSystemStatus()
    }
  }, [router.pathname])

  useEffect(() => {
    if (!isSystemStatusLoading && getSystemValue('underConstruction')) {
      router.push(pageLinkCommon.maintenance)
    }
  }, [getSystemValue('underConstruction')])
  /* ------------------------------- */

  return null
}
