/* eslint-disable  */

export default function LegalContent() {
  return (
    <>
      <strong>หลักการโดยทั่วไป</strong>
      <p>
        ข้อกำหนดและเงื่อนไขการใช้งานมีผลบังคับใช้ในทันทีที่ท่านกดปุ่ม "ยอมรับ"
        ซึ่ง เป็นการยืนยันกับ PIGBET ว่าท่านได้อ่านเงื่อนไขและข้อตกลง
        เหล่านี้พร้อมทั้งยอมรับข้อตกลงดังกล่าวในการใช้งานเว็บไซต์แล้ว
        ไม่ว่าจะเป็นขั้นตอนการลงทะเบียนบัญชี การทำธุรกรรมต่าง ๆ
        เกี่ยวกับการใช้งานทั้งหมด
      </p>
      <p>&nbsp;</p>
      <strong>ข้อกําหนดและเงื่อนไขการใช้งาน</strong>
      <p>
        ข้อกําหนดและเงื่อนไขการใช้งานนี้มีผลบังคับใช้กับโดเมนหลักของ PIGBET.app
      </p>
      <p>&nbsp;</p>
      <p>
        ข้อกําหนดและเงื่อนไปการใช้งานที่แสดงไว้ ณ ที่นี้ (ต่อไปนี้เรียกว่า
        "ข้อกําหนดและเงื่อนไขฯ") ระบุถึงข้อกําหนดระหว่าง www.PIGBET.app
        (ต่อไปนี้เรียกว่า "PIGBET") และผู้ใช้ (ต่อไปนี้เรียกว่า "ผู้ใช้")
        เกี่ยวกับบริการหรือคุณลักษณะใดๆ ของ PIGBET (ต่อไปนี้เรียกว่า "บริการฯ")
        ซึ่ง PIGBET เป็นผู้ให้บริการ
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1.คํานิยาม</strong>
        คําและข้อความต่อไปนี้จะมีความหมายตามที่กําหนดไว้ด้านล่างนี้เมื่อมีการใช้ในข้อกําหนดและเงื่อนไขฯ
      </p>
      <p>&nbsp;</p>
      <p>
        1.1 “เนื้อหา” หมายถึง ข้อมูล เช่น ข้อความ ไฟล์เสียง เพลง รูปภาพ วิดีโอ
        ซอฟต์แวร์ โปรแกรม รหัสคอมพิวเตอร์ และสิ่งอื่นๆ ที่คล้ายคลึงกัน
      </p>
      <p>
        1.2 "เนื้อหาจากผู้ใช้" หมายถึง เนื้อหาที่ผู้ใช้ได้ส่ง ส่งผ่าน หรือ
        อัพโหลด
      </p>
      <p>
        1.3 “สินค้า” หมายถึง สินค้าที่สามารถเกิดการซื้อได้โดยใช้เงิน หรือ เครดิต
        ซึ่งในที่นี้หมายถึง เกมสล็อต และเกมอื่น ๆ ของ PIGBET
      </p>
      <p>
        1.4 “เครดิต" หมายถึง
        สกุลเงินเสมือนจริงซึ่งผู้ใช้สามารถใช้เพื่อชําระสําหรับเนื้อหา สินค้า
        และบริการต่างๆ ที่ PIGBET เสนอ ซึ่งมีค่าบริการกำหนดให้ชำระด้วย เครดิต
      </p>
      <p>
        1.5 "ข้อกําหนดและเงื่อนไขฯ เพิ่มเติม" หมายถึง เอกสารที่ PIGBET
        ออกหรืออัพโหลดซึ่งเกี่ยวกับบริการฯ
      </p>
      <p>&nbsp;</p>

      <p>
        <strong>2.การตกลงยอมรับข้อกําหนดและเงื่อนไขฯ</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        2.1 ผู้ใช้ทุกรายจะต้องใช้บริการฯ
        ตามข้อกําหนดที่ระบุไว้ในข้อกําหนดและเงื่อนไขฯ
        ผู้ใช้จะไม่สามารถใช้บริการฯ ได้เว้นเสียแต่ว่า
        ผู้ใช้ได้ตกลงยอมรับข้อกําหนดและ เงื่อนไขฯ
      </p>
      <p>
        2.2 หากมีข้อกําหนดและเงื่อนไขฯ เพิ่มเติมสําหรับบริการฯ
        ผู้ใช้จะต้องปฏิบัติตามข้อกําหนดและเงื่อนไขฯ
        เพิ่มเติมนั้นด้วยเช่นเดียวกับข้อกําหนดและเงื่อนไขฯ นี้
      </p>
      <p>&nbsp;</p>

      <strong>3.การแก้ไขข้อกําหนดและเงื่อนไขฯ</strong>
      <p>
        PIGBET อาจแก้ไขข้อกําหนดและเงื่อนไขฯ หรือ ข้อกําหนดและเงื่อนไขฯ
        เพิ่มเติม เมื่อ PIGBET
        เห็นว่ามีความจําเป็นโดยไม่ต้องให้คำบอกกล่าวล่วงหน้าแก่ผู้ใช้
      </p>
      <p>&nbsp;</p>
      <p>
        การแก้ไขจะมีผลบังคับเมื่อมีการประกาศข้อกําหนดและเงื่อนไขฯ ที่แก้ไข
        หรือข้อกําหนดและเงื่อนไขฯ
        เพิ่มเติมที่แก้นั้นไปไว้ในสถานที่ที่เหมาะสมภายในเว็บไซต์ที่ PIGBET
        เป็นผู้ดําเนินงานต่อไป
        ถือว่าผู้ใช้ได้ให้การยอมรับซึ่งมีผลสมบูรณ์และเพิกถอนมิได้ต่อข้อกําหนดและเงื่อนไขฯ
        ที่แก้ไข หรือข้อกําหนดและเงื่อนไขฯ เพิ่มเติมที่แก้ไขนั้นแล้ว
        ผู้ใช้ควรตรวจสอบข้อกําหนดและเงื่อนไขฯ เป็นประจําระหว่างการใช้บริการฯ
        PIGBET ไม่จำเป็นต้องแจ้งเตือนถึงการแก้ไขข้อกําหนดและเงื่อนไขฯ ดังกล่าว
      </p>
      <p>&nbsp;</p>

      <strong>4.บัญชี</strong>
      <p>
        4.1 เมื่อผู้ใช้ให้ข้อมูลเกี่ยวกับตนเองแก่ PIGBET
        ผู้ใช้จะต้องให้ข้อมูลที่เป็นจริง ถูกต้องและครบถ้วนในขณะที่ใช้บริการฯ
        และปรับปรุงข้อมูลดังกล่าวให้เป็นปัจจุบันอยู่ตลอดเวลา
      </p>
      <p>
        4.2 หากผู้ใช้ลงทะเบียนรหัสผ่านในขณะที่ใช้บริการ
        ผู้ใช้จะต้องใช้ความระมัดระวังและมีความรับผิดชอบตาม
        สมควรเพื่อไม่ให้มีการใช้รหัสผ่านในลักษณะที่ไม่ชอบด้วยกฎหมาย PIGBET
        สามารถถือว่ากิจกรรมต่างๆ ที่ดําเนินการโดยใช้รหัสผ่านดังกล่าว
        เป็นกิจกรรมที่ผู้เป็นเจ้าของรหัสผ่านได้ดําเนินการด้วยตนเอง
      </p>
      <p>
        4.3 PIGBET
        อาจระงับหรือลบบัญชีของผู้ใช้ได้โดยไม่ต้องให้คําบอกกล่าวล่วงหน้าแก่ผู้ใช้
        หาก PIGBET เห็นว่าผู้ใช้กําลังละเมิดหรือได้ละเมิดข้อกําหนดและเงื่อนไขฯ
      </p>
      <p>
        4.4 บัญชีแต่ละบัญชีในบริการฯ
        มีไว้เพื่อการใช้งานเฉพาะและเป็นของเจ้าของบัญชีแต่เพียงผู้เดียวเท่านั้น
        ผู้ใช้ไม่สามารถโอนหรือให้ยืมบัญชีของตนแก่บุคคลภายนอกใดๆ
        และบุคคลภายนอกใดๆ ไม่สามารถรับช่วงบัญชีจากผู้ใช้ได้
      </p>
      <p>&nbsp;</p>

      <strong>5.การคุ้มครองข้อมูลส่วนบุคคล</strong>
      <p>
        PIGBET ให้ความสําคัญแก่ความเป็นส่วนตัวของผู้ใช้ของ PIGBET
        โดยสัญญาว่าจะคุ้มครองความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้ของ
        PIGBET ตาม "นโยบายความเป็นส่วนตัว"
        และจะใช้ความระมัดระวังและความใส่ใจอย่างเต็มที่เกี่ยวกับมาตรการรักษาความ
        ปลอดภัยของ PIGBET เพื่อความปลอดภัยของข้อมูลใดๆ ของผู้ใช้ทั้งหมด
      </p>
      <p>&nbsp;</p>

      <strong>6.การให้บริการฯ</strong>
      <p>
        6.1 PIGBET สงวนสิทธิ์ที่จะจํากัดการเข้าถึงบริการฯ
        ทั้งหมดหรือเพียงบางส่วน ขึ้นอยู่กับเงื่อนไขที่ PIGBET เห็นว่าจําเป็น
        เช่น อายุ การระบุตัวตน สถานภาพ ผู้ใช้ในปัจจุบัน และสิ่งอื่นๆ
        ที่คล้ายคลึงกัน
      </p>
      <p>
        6.2 PIGBET สงวนสิทธิ์ที่จะเปลี่ยนแปลงหรือยุติบริการฯ
        ทั้งหมดหรือเพียงบางส่วนตามดุลยพินิจของ PIGBET ไม่ว่าในเวลาใดๆ ก็ตาม
        โดยไม่ต้องให้คําบอกกล่าวล่วงหน้าใดๆ แก่ผู้ใช้
      </p>
      <p>&nbsp;</p>

      <strong>7.คุกกี้</strong>
      <p>
        ผู้ใช้ควรทราบว่าข้อมูลส่วนบุคคลต่างๆ
        จะถูกเก็บและสะสมโดยอัตโนมัติระหว่างการดำเนินการลงทะเบียนผ่านการ
        ใช้คุกกี้
        คุกกี้เป็นข้อมูลขนาดเล็กที่ส่งโดยเซิร์ฟเวอร์ของเว็บไซต์สู่เว็บเบราว์เซอร์ซึ่งอนุญาตให้เซิร์ฟเวอร์เก็บรวบรวม
        ข้อมูลจากเบราว์เซอร์ได้ PIGBET
        ใช้คุกกี้เพื่อติดตามลักษณะการใช้งานเบราว์เซอร์และสร้างโปรไฟล์ที่เกี่ยวกับ
        สถิติจำนวนประชากรขึ้น
        การเฝ้าดูลักษณะการใช้งานเหล่านี้และการสะสมรวบรวมข้อมูล ทำให้ PIGBET
        สามารถพัฒนาเพื่อนำเสนอบริการที่ดีที่สุดตามความต้องการของผู้ใช้
      </p>
      <p>&nbsp;</p>

      <strong>8.โฆษณา</strong>
      <p>
        PIGBET สงวนสิทธิ์ที่จะแสดงโฆษณาสําหรับ PIGBET
        หรือบุคคลภายนอกให้ผู้ใช้ผ่านทางบริการฯ
      </p>
      <p>&nbsp;</p>

      <strong>9.บริการของคู่ค้า</strong>
      <p>
        เนื้อหา สินค้า หรือบริการอื่นๆ ที่เสนอโดยคู่ค้ารายอื่นที่ร่วมมือกับ
        PIGBET อาจจะรวมอยู่ภายในบริการฯ นี้ด้วย
        โดยคู่ค้าจะต้องเป็นผู้รับผิดชอบแต่เพียงผู้เดียว เกี่ยวกับเนื้อหา สินค้า
        และ/หรือบริการที่เสนอดังกล่าว
        นอกจากนี้เนื้อหาสินค้าและบริการดังกล่าวอาจอยู่ภายใต้บังคับของข้อกําหนดและเงื่อนไขซึ่งคู่ค้าได้กําหนดไว้
        สําหรับเนื้อหาและบริการนั้นๆ
      </p>
      <p>&nbsp;</p>

      <strong>10.เครดิต</strong>
      <p>
        10.1 ผู้ใช้จะได้รับ เครดิต โดยวิธีการที่ PIGBET กําหนด เช่น
        การซื้อสินค้า ข้อเสนอพิเศษ หรือวิธีการอื่นๆ ในทํานองเดียวกัน
        รายละเอียดเกี่ยวกับวิธีการชําระเงิน หน่วย จํานวน เครดิต
        ที่จําเป็นต้องมีเพื่อซื้อสินค้า รับบริการ หรือเข้าถึงเนื้อหา
        และเงื่อนไขอื่นๆ ทั้งหมดเกี่ยวกับ เครดิต จะถูกกําหนดโดย PIGBET
        และแสดงไว้ในระหว่างการให้บริการฯ
      </p>
      <p>
        10.2 เครดิต
        ไม่สามารถนําไปแลกเปลี่ยนหรือใช้เพื่อวัตถุประสงค์อื่นนอกจากที่บริการเสนอ
      </p>
      <p>10.3 เครดิต ไม่สามารถขอคืนเป็นเงินได้ไม่ว่าในกรณีใดๆ</p>
      <p>&nbsp;</p>

      <strong>11.ข้อจํากัดการใช้บริการฯ</strong>
      <p>ผู้ใช้จะต้องไม่มีส่วนร่วมในกิจกรรมต่างๆ ดังต่อไปนี้ เมื่อใช้บริการฯ</p>
      <p>
        11.1 กิจกรรมที่อาจทําให้ผู้อื่นเข้าใจผิดเกี่ยวกับ PIGBET
        และ/หรือบุคคลภายนอก หรือจงใจเผยแพร่ข้อมูลเท็จ
      </p>
      <p>
        11.2 กิจกรรมต่างๆ เช่น การส่งข้อความโดยการสุ่มไปให้แก่ผู้ใช้จํานวนมาก
        (ยกเว้นข้อความที่ได้รับความเห็นชอบจาก PIGBET) การเพิ่มผู้ใช้เป็นเพื่อน
        หรือเข้าไปในกลุ่มพูดคุยโดยการสุ่มหรือกิจกรรมอื่นใดที่ PIGBET
        ถือว่าเป็นการส่งข้อความรบกวน (spamming)
      </p>
      <p>
        11.3 กิจกรรมที่มีการแลกเปลี่ยนสิทธิในการซื้อสินค้า รับบริการ
        หรือใช้เนื้อหาฯ เป็นเงินสด ทรัพย์สินหรือผลประโยชน์ทางเศรษฐกิจอื่นๆ
        โดยไม่ได้รับอนุญาตจาก PIGBET
      </p>
      <p>11.4 กิจกรรมอื่นๆ ที่ PIGBET เห็นว่าไม่เหมาะสม</p>
      <p>&nbsp;</p>

      <strong>12.ความรับผิดชอบของผู้ใช้</strong>
      <p>
        12.1 ผู้ใช้จะต้องใช้บริการฯ นี้โดยเป็นความเสี่ยงของผู้ใช้เอง
        และจะต้องรับผิดชอบแต่เพียงผู้เดียวสําหรับการกระทําที่กระทําไปและผลของการกระทําที่มีต่อบริการฯ
        นี้
      </p>
      <p>
        12.2 PIGBET อาจใช้มาตรการที่ PIGBET เห็นว่าจําเป็นและเหมาะสมได้ หาก
        PIGBET รับทราบว่าผู้ใช้รายหนึ่งรายใดกําลังใช้บริการฯ
        ในทางที่ฝ่าฝืนข้อกําหนดและเงื่อนไขฯ อย่างไรก็ตาม PIGBET
        ไม่ต้องรับผิดชอบในการแก้ไขหรือป้องกันการฝ่าฝืนดังกล่าวต่อผู้ใช้หรือบุคคลอื่นๆ
      </p>
      <p>
        12.3 ผู้ใช้ พึงใช้บริการฯ PIGBET
        ตามข้อกำหนดและเงื่อนไขการใช้บริการในทางปกติตามที่ PIGBET
        กำหนดไว้เท่านั้น ไม่กระทำการในลักษณะใช้กลอุบาย หรือฉ้อโกงในลักษณะใดๆ
        ในการใช้บริการฯ หรือในการยืนยันตัวตน
      </p>
      <p>
        12.4 ในกรณีที่เกิดความสูญเสีย หรือความเสียหายแก่ PIGBET หรือ PIGBET
        ถูกเรียกเก็บค่าใช้จ่ายใดๆ (ซึ่งรวมถึงแต่ไม่จํากัดเพียงค่าทนายความ)
        ไม่ว่าโดยตรงหรือโดยอ้อม
        (ซึ่งรวมถึงแต่ไม่จํากัดเพียงกรณีที่มีบุคคลภายนอกฟ้องร้องเรียกค่าเสียหายจาก
        PIGBET) อันเนื่องมาจากการที่ผู้ใช้ฝ่าฝืนกฎหมายที่เกี่ยวข้อง
        หรือฝ่าฝืนข้อกําหนดและเงื่อนไขฯ ในการใช้บริการฯ
        ผู้ใช้จะต้องชดใช้ค่าเสียหายให้แก่ PIGBET ทันทีที่ PIGBET ร้องขอ
      </p>
      <p>&nbsp;</p>

      <strong>13.การยกเว้นความรับผิดของ PIGBET</strong>
      <p>
        13.1 PIGBET ไม่รับประกันไม่ว่าโดยชัดแจ้งหรือโดยปริยายว่า สินค้า
        และบริการฯ (รวมถึงเนื้อหาฯ) จะปราศจากข้อบกพร่องตามจริงหรือตามกฎหมาย
        (ซึ่งรวมถึงแต่ไม่จํากัดเพียง เสถียรภาพ การไว้วางใจได้ ความถูกต้อง
        ความสมบูรณ์ การมีประสิทธิผล
        ความเหมาะสมในการใช้เพื่อวัตถุประสงค์เฉพาะข้อบกพร่องข้อ
        ผิดพลาดจุดบกพร่องที่เกี่ยวกับความปลอดภัย (bug) หรือการละเมิดสิทธิต่างๆ)
        และ PIGBET ไม่มีความรับผิดชอบในการที่จะต้องจัดหาบริการฯ
        ที่ไม่มีข้อบกพร่องดังกล่าว
      </p>
      <p>
        13.2 PIGBET ไม่ต้องรับผิดชอบต่อค่าเสียหายใด ๆ
        ที่เกิดขึ้นกับผู้ใช้อันเกี่ยวกับการใช้บริการฯ
      </p>
      <p>
        13.3 PIGBET ไม่ต้องรับผิดชอบต่อค่าเสียหายโดยอ้อม ค่าเสียหายพิเศษ
        ค่าเสียหายเนื่องจากการผิดสัญญา
        ค่าเสียหายต่อเนื่องหรือค่าเสียหายเชิงลงโทษใดๆ
        (ซึ่งรวมถึงแต่ไม่จํากัดเพียงค่าเสียหายที่ PIGBET
        หรือผู้ใช้สามารถคาดการณ์หรือน่าจะคาดการณ์ได้)
        ในส่วนที่เกี่ยวกับการผิดสัญญาหรือการกระทําละเมิดของ PIGBET
        เนื่องจากความประมาทเลินเล่อของ PIGBET
        (ยกเว้นความประมาทเลินเล่ออย่างร้ายแรง)
        ค่าชดเชยสําหรับค่าเสียหายตามปกติในส่วนที่เกี่ยวกับการผิดสัญญาหรือการกระทําละเมิดของ
        PIGBET เนื่องจากความประมาทเลินเล่อของ PIGBET
        จะจํากัดไว้ไม่เกินจํานวนเงินรวมของค่าบริการที่ได้รับจากผู้ใช้ใน
        เดือนปฏิทินที่เกิดค่าเสียหายดังกล่าวนั้น
      </p>
      <p>&nbsp;</p>

      <strong>14.การแจ้งเตือนและการติดต่อ</strong>
      <p>
        14.1 เมื่อ PIGBET แจ้งหรือติดต่อผู้ใช้เกี่ยวกับบริการฯ
        บริษัทอาจใช้วิธีการที่ PIGBET เห็นว่าเหมาะสม เช่น
        การประกาศไว้ในเว็บไซต์ซึ่ง PIGBET เป็นผู้ดําเนินงาน
      </p>
      <p>
        14.2 เมื่อผู้ใช้แจ้งหรือติดต่อ PIGBET เกี่ยวกับบริการ
        ผู้ใช้จะสามารถใช้ช่องทางการติดต่อตามที่ PIGBET กําหนด
      </p>
      <p>&nbsp;</p>

      <strong>Privacy Policy</strong>
      <strong>นโยบายความเป็นส่วนตัว</strong>
      <p>&nbsp;</p>

      <strong>1.การเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
      <p>
        ผู้ใช้ตกลงยินยอมให้ PIGBET สามารถเข้าถึง เก็บรวบรวม
        และประมวลผลข้อมูลส่วนบุคคลของตน ตามที่ได้ให้รายละเอียดไว้ในเว็บไซต์
        หรือที่ PIGBET เก็บรวบรวมจากแหล่งอื่นหรือบุคคลอื่นใดโดยถูกต้อง
        ตามกฎหมายที่ใช้บังคับอยู่ในขณะนั้น ไม่ว่าจะเป็นชื่อ นามสกุล
        วันเดือนปีเกิด หมายเลขโทรศัพท์ เลขประจำตัวประชาชน หมายเลขหลังบัตร
        ข้อมูลบัญชีธนาคาร รายการธุรกรรมทางบัญชีที่ทำร่วมกับทางบริษัท
        รายการซื้อสินค้าหรือบริการใดๆ
        รวมถึงการให้ข้อมูลจากการสอบถามหรือมีปฏิสัมพันธ์ใดๆ กับบริษัท เช่น
        การให้ข้อคิดเห็น คำติชม คำถาม ของผู้ใช้ด้วย
      </p>
      <p>&nbsp;</p>

      <p>
        <strong>
          2.วัตถุประสงค์ของการเข้าถึง เก็บรวบรวม การประมวลผล
          และการเปิดเผยข้อมูลส่วนบุคคล
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ผู้ใช้ตกลงและยอมรับให้มีการเข้าถึง เก็บรวบรวม ประมวลผล
        และเปิดเผยข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ ดังนี้
      </p>
      <p>&nbsp;</p>

      <p>
        (ก) เพื่อเป็นการปฏิบัติตามสัญญาระหว่าง ผู้ใช้ กับ PIGBET
        เพื่อดำเนินการตามคำขอของผู้ใช้ก่อนเข้าทำ สัญญานั้น
        เพื่อปฎิบัติตามสัญญาระหว่าง PIGBET และบุคคลอื่นอันเป็นประโยชน์ของผู้ใช้
        ใช้ในการประมวลผลข้อมูลเพื่อการพัฒนาประสิทธิภาพของสินค้าและบริการ
        การจัดหรือทำการตลาดเกี่ยวกับสินค้า บริการ และสิทธิประโยชน์
        การจัดกิจกรรมส่งเสริมการขาย การทำวิจัย วิเคราะห์และสำรวจทางการตลาด
        การจัดทำข้อมูลสถิติ การนำเสนอสินค้าหรือบริการใหม่ให้แก่ผู้ใช้ในภาพรวม
      </p>
      <p>&nbsp;</p>

      <p>
        (ข) ประมวลผลข้อมูลเพื่อการพัฒนาประสิทธิภาพของสินค้าและบริการ
        การจัดหรือทำการตลาดเกี่ยวกับสินค้า บริการ และสิทธิประโยชน์
        การจัดกิจกรรมส่งเสริมการขาย การทำวิจัย วิเคราะห์และสำรวจทางการตลาด
        การจัดทำข้อมูลสถิติ การนำเสนอสินค้าหรือบริการใหม่ให้แก่ผู้ใช้รายบุคคล
        โดย PIGBET จะใช้ข้อมูลดังกล่าว
        ให้สอดคล้องวัตถุประสงค์และให้เกิดประโยชน์สูงสุดกับผู้ใช้ ทั้งนี้
        การเก็บรวบรวมและใช้ข้อมูลดังกล่าวเป็นไปตาม หลักเกณฑ์และนโยบายตามที่
        PIGBET กำหนด และกฎหมายที่เกี่ยวข้องบัญญัติให้กระทำการดังกล่าวได้
      </p>
      <p>&nbsp;</p>

      <p>
        (ค) การติดต่อ ประสานงาน ให้ความช่วยเหลือผู้ใช้อย่างปลอดภัย
        และมีประสิทธิภาพ รวมถึงการเก็บรวบรวม ข้อมูลระหว่างการให้บริการ
        เพื่อนำไปพัฒนาระบบการดูแลลูกค้าให้เกิดประโยชน์และความพึงพอใจสูงสุดแก่ผู้ใช้
      </p>
      <p>&nbsp;</p>

      <strong>3.การเปิดเผยข้อมูลส่วนบุคคล</strong>
      <p>
        ผู้ใช้ตกลงและยอมรับว่า PIGBET
        อาจทำการเปิดเผยหรือส่งข้อมูลส่วนบุคคลให้แก่ฝ่ายพัฒนาเว็บไซต์
        ฝ่ายบริหารงาน ฝ่ายบุคคล ฝ่ายการตลาด และฝ่ายลูกค้าสัมพันธ์ของบริษัท
        เพื่อดำเนินการตามวัตถุประสงค์ ตามข้อ 2 รวมถึงผู้สอบบัญชี
        ผู้ตรวจสอบภายนอกของบริษัท และหน่วยงานราชการตามที่กฎหมายกำหนด
      </p>
      <p>&nbsp;</p>

      <p>
        <strong>4.ระยะเวลาในการเก็บและใช้ข้อมูลส่วนบุคคล</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ผู้ใช้ตกลงและยินยอมให้บริษัท
        ประมวลผลข้อมูลส่วนบุคคลของตนได้ตลอดระยะเวลาที่ผู้ใช้ยังเป็นผู้ใช้
        และให้ทำลายหรือดำเนินการให้ข้อมูลส่วนบุคคลดังกล่าวไม่เป็นข้อมูลส่วนบุคคลอีกต่อไปภายในกำหนดเวลา
        10 ปี นับแต่วันที่ความเป็นผู้ใช้สิ้นสุดลง เว้นแต่ ข้อมูลส่วนบุคคลนั้น
        จะไม่สามารถทำลายหรือลบได้อันเนื่องมาจาก ความจำเป็นของบริษัท
        ที่ต้องเก็บรักษาตามสัญญาอันเกี่ยวพันกับบุคคลอื่นหรือตามที่กฎหมายกำหนด
      </p>
      <p>&nbsp;</p>

      <p>
        <strong>5.ผลกระทบจากการไม่ให้ข้อมูลส่วนบุคคล</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ผู้ใช้ทราบว่าการไม่ให้ข้อมูลส่วนบุคคลดังกล่าว จะทำให้ไม่สามารถใช้งาน
        PIGBET ได้อย่างสมบูรณ์ และจะไม่สามารถซื้อสินค้า
        หรือใช้บริการที่อยู่ภายในเว็บไซต์ PIGBET ได้
        รวมถึงการที่ไม่สามารถได้รับสิทธิ ประโยชน์ต่างๆ จาก PIGBET
      </p>
      <p>&nbsp;</p>

      <p>
        <strong>6.รายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        หากมีเหตุร้องเรียนเกี่ยวกับข้อมูลส่วนบุคคลสามารถติดต่อประสานงานมายังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลได้
        ในช่องทางดังนี้
      </p>
      <p>&nbsp;</p>

      <p>ผู้ควบคุมข้อมูลส่วนบุคคล</p>
      <p>LIN PULI INVESMENT CO.,LTD</p>
      <p>&nbsp;</p>
    </>
  )
}
