import cx from 'classnames'
import { useRouter } from 'next/router'
import { Button } from '@pig-frontend/uikit'
import Image from 'next/image'
import styles from './index.module.scss'

interface FloatingButtonProps {
  path: string
  imageURL: string
  alt: string
  className?: string
}

function FloatingButton({
  path,
  imageURL,
  alt,
  className,
}: FloatingButtonProps) {
  const router = useRouter()

  return (
    <Button
      type="primary"
      className={cx(styles.container, className)}
      onClick={() => router.push(path)}
    >
      <Image src={imageURL} layout="fill" objectFit="cover" alt={alt} />
    </Button>
  )
}

export { FloatingButton }
