import { atom, selector } from 'recoil'
import { ThemeTypes } from '@pig-frontend/uikit'

/**
 *
 * TODO move me to UIKit because theme data
 * or theme switchingfunction is used
 * by many projects
 *
 */

/**
 * Keys
 */
export const key = {
  theme: 'CASINO/THEME',
  switchTheme: 'CASINO/SWITCH-THEME',
  resetTheme: 'CASINO/RESET-THEME',
}

/**
 * initialValue
 */
export const defaultTheme: ThemeTypes = 'light'

/**
 * States
 */

export const theme = atom<ThemeTypes>({
  key: key.theme,
  default: defaultTheme,
})

/**
 * State actions
 */
export const switchTheme = selector({
  key: key.switchTheme,
  get: ({ get }) => get(theme),
  set: ({ set, get }) => {
    const _theme = get(theme)
    set(theme, _theme === 'light' ? 'dark' : 'light')
  },
})

export const resetTheme = selector({
  key: key.resetTheme,
  get: ({ get }) => get(theme),
  set: ({ set }) => {
    set(theme, 'light')
  },
})
