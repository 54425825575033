import cx from 'classnames'
import styles from './index.module.scss'

export type IVIPTierTypes = 'bronze' | 'silver' | 'gold' | 'diamond'

export interface IVIPTagProps {
  tier?: IVIPTierTypes
  level?: string
  className?: string
}

export function VIPTag({ tier, level, className }: IVIPTagProps) {
  return (
    <div
      className={cx(
        styles.container,
        styles[tier?.toLowerCase() || 'none'],
        className,
      )}
    >
      {level}
    </div>
  )
}
