import React from 'react'
import { Modal } from '@pig-frontend/uikit'
import { TipRainModalProps } from './TipRain.type'

const withTipRainModal = <P extends object>(
  ModalContentComponent: React.FC<P & TipRainModalProps>,
) => {
  return (props: P & TipRainModalProps) => {
    const { modalProps, toClose, wrapperClassName } = props
    return (
      <div aria-hidden onClick={(e) => e.stopPropagation()}>
        <Modal
          centered
          wrapClassName={wrapperClassName}
          destroyOnClose
          footer={null}
          onCancel={toClose}
          {...modalProps}
        >
          <ModalContentComponent {...props} />
        </Modal>
      </div>
    )
  }
}

export default withTipRainModal
