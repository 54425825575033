import { ReactNode } from 'react'
import { Layout as AntdLayout } from 'antd'
import { ThemeTypes } from '@pig-frontend/uikit'
import Body, { BodyProps } from '../body'
import Header, { IHeaderProps } from '../header'
import Footer, { IFooterProps } from '../footer'

export interface NavbarProps {
  children?: ReactNode
  theme?: ThemeTypes
}

export function Layout({ children, theme = 'light' }: NavbarProps) {
  return <AntdLayout className={`${theme}-mode`}>{children}</AntdLayout>
}

Layout.Body = Body
Layout.Header = Header
Layout.Footer = Footer

export type { BodyProps, IHeaderProps, IFooterProps }
export default Layout
