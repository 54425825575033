import { RightOutlined } from '@ant-design/icons'
import Link from 'next/link'
import styled from 'styled-components'
import { UrlObject } from 'url'

const StyledLink = styled(Link)`
  display: flex;
  font-size: 20px;
`
const HeaderLink = ({
  sectionId,
  text,
  number,
  linkUrl,
}: {
  sectionId: string
  text: string
  linkUrl: string | UrlObject
  number?: number
}) => {
  return (
    <StyledLink
      data-testid={`${sectionId}-promotions-header-link`}
      href={linkUrl}
    >
      <span className="ps-section-title__link-text">
        {`${text} ${number ? `(${number})` : ''}`}
      </span>
      <RightOutlined />
    </StyledLink>
  )
}

export default HeaderLink
