import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { KEYS } from '../keys'
import { VipProfileData, emptyVip } from '@pig-common/hooks/useVip'

const { persistAtom } = recoilPersist()

export type VipState = {
  vip: VipProfileData
}

export const initialValue: VipState = {
  vip: emptyVip,
}

export const vipState = atom<Partial<VipState>>({
  key: KEYS.VIP,
  default: initialValue,
  effects: [persistAtom],
})

export default vipState
