import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { achievementApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IAchievementItemProps } from '@pig-common/types/Achievement.type'
import { DefaultType } from '@pig-common/types/Types.type'

interface IGetAchievementHighlightProps<
  SelectReturnType extends IAchievementItemProps &
    DefaultType = IAchievementItemProps[],
> {
  params?: { customerCode: string }
  select?: (data: CommonResponse<IAchievementItemProps>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useAchievementHighlight<
  SelectReturnType extends IAchievementItemProps &
    DefaultType = IAchievementItemProps[],
>({
  params,
  select,
  enabled,
}: IGetAchievementHighlightProps<SelectReturnType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode: myCustomerCode } = useRecoilValue(
    authStateCommon.authState,
  )

  return useQuery({
    queryKey: [
      achievementApi.key.getHighlight,
      params?.customerCode || myCustomerCode,
    ],
    queryFn: () =>
      achievementApi.getHighlight<SelectReturnType>({
        customerCode: params?.customerCode || myCustomerCode,
      }),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data) || [],
  })
}
