import { atom, selector } from 'recoil'
import { KEYS } from '../keys'
import { IBonusWSSResponse } from '@pig-common/types/Bonus.type'

export type InboxItem = {
  uid: string
  userUid: string
  title: string
  subTitle: string
  content?: string
  type: 'transaction' | 'affiliate' | 'system'
  icon?: string
  date: Date
  isRead: boolean
  start?: Date
  end?: Date
  createdAt: Date
  updatedAt: Date
  productType?: string
}

export type InboxState = {
  inboxs: InboxItem[]
  unread: number
}

export type InboxAll = {
  systemStatus: InboxState
  transaction: InboxState
  affiliate: InboxState
  sumUnread: number
}

export const defaultInboxState: InboxState = {
  inboxs: [],
  unread: 0,
}

export const defaultValue: IBonusWSSResponse = {
  activeBonus: [],
  completedBonus: [],
}

export const inboxSystemStatusState = atom<InboxState>({
  key: KEYS.INBOX_SYSTEM_STATUS,
  default: defaultInboxState,
})

export const inboxTransactionState = atom<InboxState>({
  key: KEYS.INBOX_TRANSACTION,
  default: defaultInboxState,
})

export const inboxAffiliateState = atom<InboxState>({
  key: KEYS.INBOX_AFFILIATE,
  default: defaultInboxState,
})

export const inboxBonusState = atom<IBonusWSSResponse>({
  key: KEYS.INBOX_BONUS,
  default: defaultValue,
})

const countUnread = (items: InboxItem[]): number => {
  return items?.filter((i) => !i?.isRead)?.length
}

export const allInboxSelector = selector<InboxAll>({
  key: KEYS.INBOX_ALL,
  get: ({ get }) => {
    const systemStatus = get(inboxSystemStatusState)
    const transaction = get(inboxTransactionState)
    const affiliate = get(inboxAffiliateState)
    const bonus = get(inboxBonusState)
    const unreadSystemStatus = countUnread(systemStatus?.inboxs)
    const unreadTransaction = countUnread(transaction?.inboxs)
    const unreadAffiliate = countUnread(affiliate?.inboxs)
    return {
      sumUnread: unreadSystemStatus + unreadTransaction + unreadAffiliate,
      systemStatus: {
        inboxs: systemStatus?.inboxs,
        unread: unreadSystemStatus,
      },
      transaction: {
        inboxs: transaction?.inboxs,
        unread: unreadTransaction,
      },
      affiliate: {
        inboxs: affiliate?.inboxs,
        unread: unreadAffiliate,
      },
      bonus: {
        ...bonus?.activeBonus,
        ...bonus?.completedBonus,
      },
    }
  },
})
