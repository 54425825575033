import camelCase from 'lodash/camelCase'

/**
 *
 * NOTE: convert key in every level of object to camelCase.
 * And value will always convert null to undefined. (change this if doesn't need it)
 *
 */
export const camelizeKeys = (obj: Record<string, any>): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v === null ? undefined : v))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(
          obj[key] === null ? undefined : obj[key],
        ),
      }),
      {},
    )
  }
  return obj
}
