import { useState } from 'react'
import AntdImage, {
  ImageProps as AntdImageProps,
  StaticImageData,
} from 'next/image'
import { placeholderImage as defaultPlaceholderImage } from '@pig-frontend/uikit/constants/images'
import styles from './index.module.scss'

export interface ImageProps extends AntdImageProps {
  placeholderImage?: StaticImageData | string
}

export function Image({
  src,
  placeholderImage,
  width,
  height,
  ...props
}: ImageProps) {
  const [isError, setError] = useState<boolean>(false)

  return (
    <>
      {isError ? (
        <div
          className={styles['error-container']}
          style={{
            maxWidth: width,
            maxHeight: height,
          }}
        >
          <AntdImage
            {...props}
            src={placeholderImage || defaultPlaceholderImage}
            width={width}
            height={height}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <AntdImage
          src={src}
          onError={() => setError(true)}
          placeholder="blur"
          blurDataURL="empty"
          width={width}
          height={height}
          {...props}
        />
      )}
    </>
  )
}
