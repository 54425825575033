import { atom } from 'recoil'

const key = {
  chatChannel: 'COMMON/CHAT_CHANNEL',
  chatPanelVisible: 'COMMON/CHAT_VISIBLE',
}

export type chatChannelRecoil = {
  channels: string[]
  currentChannel: string
  unreadMessage: {
    [channel: string]: number
  }
  unreadMessageTotal: number
  forceReconnection?: boolean
}

export const defaultChatChannel = {
  channels: ['channel-public'],
  currentChannel: '',
  unreadMessage: {
    'channel-public': 0,
  },
  unreadMessageTotal: 0,
}

export const defaultChatPanelVisible = false

export const chatChannelState = atom<chatChannelRecoil>({
  key: key.chatChannel,
  default: defaultChatChannel,
})

export const chatPanelVisibleState = atom<boolean>({
  key: key.chatPanelVisible,
  default: defaultChatPanelVisible,
})
