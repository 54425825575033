import React, { ReactNode } from 'react'
import cx from 'classnames'
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'
// import { withRippleEffect } from './ripple'
import styles from './index.module.scss'

export interface ButtonProps extends AntdButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  children?: ReactNode[] | ReactNode
  disableContent?: boolean
}

function Button({
  children,
  onClick,
  className,
  disableContent = false,
  ...props
}: ButtonProps) {
  return (
    <AntdButton
      data-testid="uikit-btn"
      className={cx(styles.container, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </AntdButton>
  )
}

export { Button }

/** Uncomment me if wanna using ripple button */
// const ButtonRipple = withRippleEffect(Button)
// export { ButtonRipple as Button }
