import axios from 'axios'
import {
  API_URL,
  BO_API_URL,
  PIGSPIN_API_URL,
  QR_PAYMENT_API_URL,
} from '@pig-common/models/buildtime-constant'

export const phpApiInstance = axios.create({
  baseURL: API_URL,
  // timeout: 5000,
})

export const boApiInstance = axios.create({
  baseURL: BO_API_URL,
})

export const pigspinApiInstance = axios.create({
  baseURL: PIGSPIN_API_URL,
  // timeout: 5000,
})

pigspinApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error: any) => {
    return error.response
  },
)

export const qrPaymentApiInstance = axios.create({
  baseURL: QR_PAYMENT_API_URL,
  // timeout: 7500,
})

export const lineApiInstance = axios.create({
  baseURL: 'https://api.line.me',
  timeout: 8000,
})
