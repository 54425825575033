export const addCommas = (number: string): string => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const removeCommas = (number: string): string => {
  return number.replace(/,/g, '')
}

export const numberWithCommas = (number: number, decimal?: number): string => {
  const numStr =
    !decimal && decimal !== 0 ? `${number}` : number.toFixed(decimal)
  const [numVal, decimalVal] = numStr.split('.')
  const result = `${addCommas(numVal)}${decimalVal ? `.${decimalVal}` : ''}`
  return result
}

export const numberWithoutCommas = (number: number | string): number => {
  const result = parseFloat(removeCommas(number.toString()))
  return result || 0
}

export const validateNumber = (val: any) => !Number.isNaN(+val)
