import { useQuery } from '@tanstack/react-query'
import { systemStatusApi } from '@pig-common/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { ISystemStatusResponse } from '@pig-common/types/SystemStatus.type'
import useLegacySystemStatus from '@pig-common/hooks/useLegacySystemStatus'
import { upperFirstKeys, snakeCaseKeys } from '@pig-frontend/utils'

interface ISystemStatusHook {
  queryKey?: string[]
  select?: (
    data: CommonResponse<ISystemStatusResponse>,
  ) => ISystemStatusResponse
  enabled?: boolean
}

export default function useSystemStatus({
  queryKey = [],
  select,
  enabled,
}: ISystemStatusHook) {
  const { collectSystemStatus } = useLegacySystemStatus()

  return useQuery({
    queryKey: [systemStatusApi.key.get, ...queryKey],
    queryFn: () => systemStatusApi.get(),
    enabled,
    select: (data) => (select ? select(data) : data?.data),
    onSuccess: (data) => {
      // Make compatible with super legacy code 👎
      delete data.scbError
      delete data.freeStyleScb
      delete data.freeStyleTextScb
      collectSystemStatus({
        ...upperFirstKeys(data),
        Inbox: snakeCaseKeys(data?.inbox || []),
        SCBError: data?.scbError,
        FreeStyleSCB: data?.freeStyleScb,
        FreeStyleTextSCB: data?.freeStyleTextScb,
      })
    },
  })
}
