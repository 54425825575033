import { atom } from 'recoil'

export type GameItem = {
  campaign_code: string
  game_code_list: string[]
}

export type GamesState = {
  data: GameItem
}

export const key = {
  rankingGameState: 'COMMON/RANK_GAME',
}

export const defaultRankingGameState: Partial<GamesState> | undefined = {
  data: undefined,
}

export const rankingGameState = atom<Partial<GamesState | undefined>>({
  key: key.rankingGameState,
  default: defaultRankingGameState,
})
