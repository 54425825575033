import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

export const key = {
  authState: 'COMMON/AUTH',
  isAuthenState: 'COMMON/AUTH_STATUS',
}

const { persistAtom } = recoilPersist()

export type AuthState = {
  cfid: string
  customerCode: string
  name: string
  gameToken: string
  isFirstLogin?: boolean
  isRenewPin?: boolean
  isRegisterLineSuccess?: boolean
  isLoginPhoneSuccess?: boolean
  userUID: string
  authInterceptor: number
}

export const defaultAuthState: AuthState = {
  cfid: '', // Obsolete
  customerCode: '',
  name: '',
  gameToken: '',
  userUID: '',
  authInterceptor: 0,
  isFirstLogin: false,
  isRenewPin: false,
  isRegisterLineSuccess: false,
  isLoginPhoneSuccess: false,
}

export const authState = atom<Partial<AuthState>>({
  key: key.authState,
  default: defaultAuthState,
  effects: [persistAtom],
})

export const isAuthenState = selector({
  key: key.isAuthenState,
  get: ({ get }) => {
    const { cfid, customerCode, userUID } = get(authState)
    return !!(cfid && customerCode && userUID)
  },
})
