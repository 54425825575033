import { Image, VIPTag, IVIPTagProps } from '@pig-frontend/uikit'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import {
  adminImage,
  pixelImage,
  pixxaImage,
  richyImage,
  shinyImage,
} from '@pig-frontend/uikit/constants/images'
import { StaticImageData } from 'next/image'
import styles from './index.module.scss'

export type ProfileAvatarTypes = 'pixel' | 'pixxa' | 'shiny' | 'richy' | 'admin'

type profileAvatarImageMapperValueType = {
  name: string
  img: StaticImageData
}
export const profileAvatarMapper: Record<
  ProfileAvatarTypes,
  profileAvatarImageMapperValueType
> = {
  pixel: { name: 'พิกเซล', img: pixelImage },
  pixxa: { name: 'ไชน์นี่', img: pixxaImage },
  richy: { name: 'พิกซ่า', img: richyImage },
  shiny: { name: 'ริชชี่', img: shinyImage },
  admin: { name: 'แอดมิน', img: adminImage },
}

interface IProfileAvatarProps {
  name?: ProfileAvatarTypes
  /** IMPROVE: Remove legacy code that using avatar image with external url.
   * Make "imgUrl" prop for support legacy code only
   * Stop using external url and pass only avatar name
   */
  imgUrl?: string
  className?: string
  imgClassName?: string
  width?: number
  height?: number
  round?: boolean
  vip?: IVIPTagProps
}

export function ProfileAvatar({
  name,
  imgUrl,
  className,
  imgClassName,
  vip,
  height,
  width,
  round = true,
}: IProfileAvatarProps) {
  return (
    <div className={cx(styles.container, className)}>
      <Image
        src={imgUrl || profileAvatarMapper[name || 'pixel']?.img}
        alt={name as string}
        className={cx({ [styles.round]: round, imgClassName })}
        width={width}
        height={height}
      />
      {!isEmpty(vip) && (
        <VIPTag
          level={vip?.level}
          tier={vip?.tier}
          className={cx(styles['vip-tag'], vip?.className)}
        />
      )}
    </div>
  )
}
