import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { readFlagApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IReadFlagData } from '@pig-common/types/ReadFlag.type'

interface IGetReadFlag<SelectReturnType = IReadFlagData> {
  params?: readFlagApi.IGetReadFlagParams
  select?: (data: CommonResponse<IReadFlagData>) => SelectReturnType
  enabled?: boolean
}

export default function useGetReadFlag<SelectReturnType = IReadFlagData>({
  params,
  select,
  enabled,
}: IGetReadFlag<SelectReturnType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { userUID } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [readFlagApi.key.get, params?.fieldName],
    queryFn: () => readFlagApi.get({ userUID, fieldName: params?.fieldName }),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
  })
}
