const recoilAction = {
  removeByKey: (key: string) => {
    // stil stayed in server side
    if (window === undefined) return

    const data = window.localStorage.getItem('recoil-persist')

    if (!data) return

    const jsonData = JSON.parse(data)

    delete jsonData[key]

    window.localStorage.setItem('recoil-persist', JSON.stringify(jsonData))
  },
  removeAll: () => {
    if (window === undefined) return

    window.localStorage.removeItem('recoil-persist')
  },
}

export default recoilAction
