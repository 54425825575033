import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { dailySpinApi } from '@pig-common/api'
import { Modal } from '@pig-frontend/uikit'
import { InfoCircleOutlined } from '@ant-design/icons'
import { CommonErrorResponse } from '@pig-common/types/Api.type'
import { COOKIES_KEY } from '@pig-common/utils/cookies'

export default function useDailySpinCheckIn() {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const [cookies, setCookies] = useCookies([
    COOKIES_KEY.DAILY_LOGIN,
    COOKIES_KEY.CFID,
    COOKIES_KEY.CUSTOMER_CODE,
  ])
  const cfid = cookies?.[COOKIES_KEY.CFID]
  const customerCode = cookies?.[COOKIES_KEY.CUSTOMER_CODE]
  const isAuthenticatedCookies = cfid && customerCode

  const flagDailyLogin = () => {
    if (!cookies?.daily_login) {
      setCookies(COOKIES_KEY.DAILY_LOGIN, dayjs().unix(), {
        expires: dayjs().endOf('day').toDate(),
      })
    }
  }

  return useMutation({
    mutationFn: async () => {
      if (isAuthenticated && !cookies?.daily_login) {
        return await dailySpinApi.createCheckIn()
      }
      return null
    },
    onSuccess: (data) => {
      if (isAuthenticated) {
        if (data?.status === 'SUCCESS') {
          flagDailyLogin()
        } else if (
          isAuthenticatedCookies &&
          data?.status === 'ERROR' &&
          data?.serviceCode !== 'PIG-15101' // No refilled balance before; couldnot let this error always show
        ) {
          Modal.error({
            icon: <InfoCircleOutlined />,
            title: (
              <strong>{(data?.data as CommonErrorResponse)?.message}</strong>
            ),
            content: (data?.data as CommonErrorResponse)?.detail,
            centered: true,
            okText: 'ตกลง',
          })
        }
      }
    },
  })
}
