import isEmpty from 'lodash/isEmpty'
import { useEffect, useRef } from 'react'
import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'
import { GameItem } from '@pig-common/services/games'

export const MQ_LIST = ['ss', 'xs', 'sm', 'md', 'lg', 'xl'] as const
export type MQType = typeof MQ_LIST[number]

export function getMQ(width: number) {
  let mq: MQType = 'ss'
  if (width >= 320 && width < 375) {
    mq = 'xs'
  }
  if (width >= 375 && width < 768) {
    mq = 'sm'
  }
  if (width >= 768 && width < 1024) {
    mq = 'md'
  }
  if (width >= 1024 && width < 1440) {
    mq = 'lg'
  }
  if (width >= 1440) {
    mq = 'xl'
  }
  return mq
}

export function isBreakingUp(mq: MQType, up: MQType) {
  const start = MQ_LIST.indexOf(up)
  const includesList = MQ_LIST.slice(start)
  return includesList.includes(mq)
}

export function checkMQ(width: number, arr: string[]) {
  const mq = getMQ(width)
  return arr.includes(mq)
}

export function gameSlug(vendor?: string, gameCode?: string) {
  if (!vendor || !gameCode) return ''
  return `${vendor.toLowerCase()}-${gameCode}`
}

const gameAssetMapper: Record<string, string> = {
  'maquee-cover': 'marquee_url',
  'marquee-hl-img-xs-sm': 'marquee_hl_url',
  'marquee-hl-placeholder-md-lg-xl': 'marquee_hl_placeholder_url',
  'new-game-card-cover': 'new_game_url',
  'banner-highlight-md-lg-xl': 'banner_md_lg_xl_url',
  'banner-highlight-xs-sm': 'banner_xs_sm_url',
  icon: 'icon_url',
  'recent-game-card-cover': 'recent_game_url',
  'game-detail-banner': 'banner_url',
  'marquee-hl-vdo-md-lg-xl': 'vdo_url',
}
export function gameAssetUrl(
  game?: GameItem | null,
  fileName?: string,
): string {
  if (isEmpty(game) || game === null || !fileName) return ''
  return ((game?.[gameAssetMapper?.[fileName] as keyof GameItem] as string) ??
    game?.[fileName as keyof GameItem] ??
    '') as string
}

export function walletAssetUrl(fileName?: string) {
  if (!fileName) return ''
  return `${CLOUD_STORAGE_CDN}/pigspin-assets/wallets/${fileName}`
}

export function getGameCodeFromSlug(slug: string): string {
  const index = slug.indexOf('-')

  if (index === -1) {
    return slug
  }

  return slug.slice(index + 1)
}

export function toCamelCase(obj: any) {
  let newObj
  let newKey
  let value

  if (obj instanceof Array) {
    newObj = []
    Object.keys(obj).forEach((key: any) => {
      value = obj[key]
      if (typeof value === 'object') {
        value = toCamelCase(value)
      }
      newObj.push(value)
    })
  } else {
    newObj = {}
    Object.keys(obj).forEach((key: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        newKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString()
        value = obj[key]
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCase(value)
        }
        newObj[newKey] = value
      }
    })
  }
  return newObj
}

export function promotionImageAssetUrl(
  type: string,
  promoCode: string,
  fileName: string,
) {
  if (!promoCode || !fileName) return ''
  return `${CLOUD_STORAGE_CDN}/pigspin-assets/${type}/${promoCode}/${fileName}`
}

export function physicalRewardImageAssetUrl(
  rewardCode: string,
  fileName: string,
) {
  if (!rewardCode || !fileName) return ''
  // return `${CLOUD_STORAGE_CDN}/pigspin-assets/${rewardCode}/${fileName}`;
  const assetUrl = `/images/pig-shop/physical-reward/${rewardCode}/${fileName}`
  return assetUrl
}

export const useDidMountEffect = (func: () => void, deps: any) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export const bankList: Record<string, string> = {
  KBANK: 'กสิกรไทย',
  SCB: 'ไทยพาณิชย์',
  KTB: 'กรุไทย',
  BBL: 'กรุงเทพ',
  BAY: 'กรุงศรี',
  TTB: 'ทีเอ็มบีธนชาต',
  TBANK: 'ธนชาต',
  LHBANK: 'LH Bank',
  IBANK: 'อิสลาม',
  UOB: 'UOB',
  GSB: 'ออมสิน',
  BAAC: 'ธกส',
  CIMB: 'CIMB',
  KK: 'เกียรตินาคินภัทร',
  GHBANK: 'อาคารสงเคราะห์',
}
