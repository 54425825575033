import { atom } from 'recoil'

export const key = {
  inputFocusState: 'COMMON/KEYBOARD_FOCUS',
}

export const defaultInputFocusState = false

export const onInputFocusState = atom<boolean>({
  key: key.inputFocusState,
  default: defaultInputFocusState,
})
