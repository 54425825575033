import { atom } from 'recoil'

interface FavoriteMeta {
  isLoading: boolean
  isSuccess: boolean
  data: string[]
}

export const key = {
  favoriteGameState: 'COMMON/FAVORITE_GAME',
}

export const defaultFavoriteGameState = {
  isLoading: false,
  isSuccess: false,
  data: [],
}

export const favoriteGameState = atom<FavoriteMeta>({
  key: key.favoriteGameState,
  default: defaultFavoriteGameState,
})
