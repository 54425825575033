import { createElement, CSSProperties, ReactNode, useMemo } from 'react'
import cx from 'classnames'
import styles from './index.module.scss'
import { SectionHeader } from '@pig-common/components/SectionHeader'
import isEmpty from 'lodash/isEmpty'

type TitleType = {
  h1?: string
  h2?: string
  h3?: string
  h4?: string
  h5?: string
  h6?: string
}

interface SectionProps {
  sectionId: string
  title?: string | TitleType
  subTitle?: string
  children?: ReactNode
  className?: string
  sectionClassName?: string
  extra?: ReactNode
  padding?: boolean
  margin?: boolean
  style?: CSSProperties
}

export function Section({
  sectionId,
  title,
  subTitle,
  children,
  extra,
  className,
  sectionClassName,
  margin = true,
  padding = true,
  style,
}: SectionProps) {
  const headerTitle = useMemo(() => {
    const isString = typeof title === 'string'
    if (isString) return <h4 className="title">{title}</h4>
    return !isEmpty(title)
      ? createElement(
          Object.keys(title)?.[0],
          {},
          // @ts-ignore
          title?.[Object.keys(title)?.[0]],
        )
      : ''
  }, [title])
  return (
    <section
      className={cx('container', styles.container, sectionClassName, {
        [styles['no-padding']]: !padding,
        [styles['no-margin']]: !margin,
      })}
      style={style}
    >
      <SectionHeader
        sectionId={sectionId}
        title={headerTitle}
        content={extra}
      />
      <div
        className={cx(
          styles.body,
          { [styles['no-margin']]: !margin },
          className,
        )}
      >
        {children}
      </div>
    </section>
  )
}
