import jwtDecode from 'jwt-decode'

function isEmpty(obj: object = {}) {
  return Object.keys(obj).length === 0
}

export type JwtTokenData = {
  exp: number
  phone_number: string
  user_uid: string
  game_token: string
}

const toUnixTimestamp = (time: number) => time * 1000
const defaultJtwData: JwtTokenData = {
  exp: 0,
  phone_number: '',
  user_uid: '',
  game_token: '',
}

export const toJwtData = (jwtToken: string): JwtTokenData => {
  try {
    const jtwData: JwtTokenData = jwtDecode(jwtToken)
    if (isEmpty(jtwData)) return defaultJtwData
    return {
      ...jtwData,
      exp: toUnixTimestamp(jtwData.exp),
    }
  } catch {
    return defaultJtwData
  }
}

export const decodeAuthToken = (jwtToken: string): JwtTokenData => {
  const jwtData: JwtTokenData = toJwtData(
    jwtToken.replace('bearer', '').replace('%20', '').trim(),
  )
  return jwtData
}
