import { useRecoilValue, useRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import { AxiosRequestConfig } from 'axios'
import { inboxStateCommon, systemStatusStateCommon } from '@pig-common/recoils'
import { useCookies, COOKIES_KEY } from '@pig-common/utils/cookies'
import { toCamelCase } from '@pig-common/utils/utils'
import { profileStateCommon } from '@pig-common/recoils'
import { PRODUCT_NAME } from '@pig-common/models/buildtime-constant'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { BaseBoResponse } from '@pig-common/services/service.type'
import { useAxios } from './useAxios'
import {
  inboxSelector,
  insertAndUpdateInboxs,
} from '@pig-common/hooks/useInbox'
import { mapToInboxItem } from '@pig-common/mappers/mapToInboxItem'

const { ERROR_STATUS_VALUE } = systemStatusStateCommon

export type SystemStatusFlag = SystemStatusToastFlag & {
  FreeStyle: boolean
  FreeStyleSCB: boolean
  FreeStyleText: string
  FreeStyleTextSCB: string
  KioskError: boolean
  SCBError: boolean
  UnderConstruction: boolean
  UnderConstructionText: string
  Valentine: boolean
  UserActiveStatus: boolean
}

type InboxMessage = {
  uid: string
  user_uid: string
  subject: string
  subtitle: string
  message: string
  inbox_type: 'transaction' | 'affiliate' | 'system'
  icon: string
  date_time: Date
  is_read: boolean
  start?: string
  end?: string
  created_at: Date
  updated_at: Date
}

type SystemStatusToastFlag = {
  DepositError: boolean
  GameError: boolean
  Register: boolean
  WithdrawError: boolean
  FriendInvite: boolean // NOTE : remove soon
}

export type SystemStatusResponse = SystemStatusFlag & {
  Inbox: InboxMessage[]
  Toast: SystemStatusToastFlag
}

const useLegacySystemStatus = () => {
  const { pathname } = useRouter()
  const { boApiInstance } = useAxios()
  const [cookies] = useCookies<string>([COOKIES_KEY.UID, COOKIES_KEY.CFID])
  const isAuthen = !!(cookies.uid && cookies.cfid)

  const [systemStatus, setSystemStatus] = useRecoilState(
    systemStatusStateCommon.systemStatusState,
  )
  const [, setInboxSystemStatus] = useRecoilState(
    inboxStateCommon.inboxSystemStatusState,
  )
  const profile = useRecoilValue(profileStateCommon.profileState)
  const calculatedState = useRecoilValue(
    systemStatusStateCommon.calculatedSystemStatusState({ isAuthen }),
  )
  const getSystemStatusMessage = () => {
    const {
      isSCBUser,
      freeStyleSCB,
      freeStyleTextSCB,
      freeStyle,
      freeStyleText,
    } = systemStatus

    if (isAuthen) {
      // SCB user free style text case
      if (isSCBUser) {
        if (freeStyleSCB && freeStyleTextSCB?.trim())
          return freeStyleTextSCB?.trim()
        if (freeStyle && freeStyleText?.trim()) return freeStyleText?.trim()
      }
      // Normal user free style text case
      if (freeStyle && freeStyleText?.trim()) return freeStyleText?.trim()
    }

    let message = ''
    if (isAuthen) {
      // registered user
      switch (calculatedState) {
        case ERROR_STATUS_VALUE.NONE:
          message = ''
          break
        case ERROR_STATUS_VALUE.DEPOSIT:
          message = 'toast.forNormalUser.depositeError'
          break
        case ERROR_STATUS_VALUE.WITHDRAW:
          message = 'toast.forNormalUser.withdrawError'
          break
        case ERROR_STATUS_VALUE.GAME:
          message = 'toast.forNormalUser.gameError'
          break
        case ERROR_STATUS_VALUE.DEPOSIT_AND_WITHDRAW:
          message = 'toast.forNormalUser.depositeAndWithdrawError'
          break
        case ERROR_STATUS_VALUE.DEPOSIT_AND_GAME:
          message = 'toast.forNormalUser.depositeAndGameError'
          break
        case ERROR_STATUS_VALUE.WITHDRAW_AND_GAME:
          message = 'toast.forNormalUser.withdrawAndGameError'
          break
        case ERROR_STATUS_VALUE.ALL:
          message = 'toast.forNormalUser.depositeAndWithdrawAndGameError'
          break
        case ERROR_STATUS_VALUE.SCB_DEPOSIT:
          message = 'toast.forSCBUser.depositeError'
          break
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW:
          message = 'toast.forSCBUser.depositeAndWithdrawError'
          break
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_GAME:
          message = 'toast.forSCBUser.depositeAndGameError'
          break
        case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW_AND_GAME:
          message = 'toast.forSCBUser.depositeAndWithdrawAndGameError'
          break
        default:
          message = ''
      }
    } else if (pathname === '/') {
      // guest user
      switch (calculatedState) {
        case ERROR_STATUS_VALUE.CLOSE_REGISTER:
          message = 'toast.closeRegister'
          break
        default:
          message = ''
      }
    }
    return message
  }

  type ProductType = 'PIG_BET' | 'PIG_SPIN'
  const systemStatusProductTypeMapper: Record<string, ProductType> = {
    PIGBET: 'PIG_BET',
    PIGSPIN: 'PIG_SPIN',
  }

  const refreshSystemStatus = async () => {
    try {
      let systemStatusResponse: Partial<SystemStatusResponse> = {
        DepositError: false,
        FreeStyle: false,
        FreeStyleSCB: false,
        FreeStyleText: '',
        FreeStyleTextSCB: '',
        FriendInvite: false,
        GameError: false,
        KioskError: false,
        Register: false,
        SCBError: false,
        UnderConstruction: false,
        UnderConstructionText: '',
        Valentine: false,
        WithdrawError: false,
        UserActiveStatus: false,
      }
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/feature-toggle/system-status?product_type=${systemStatusProductTypeMapper[PRODUCT_NAME]}`,
        method: 'GET',
      }
      const response = await fetchWithJSON<
        BaseBoResponse<SystemStatusResponse>
      >(boApiInstance, requestConfig)
      systemStatusResponse = response.data
      const { SCBError, ...excludeSCBErrorStatus } = systemStatusResponse
      const isSCBUser = profile?.bankAccountList?.[0].bankCode === 'SCB'

      const updatedSystemStatus = {
        ...toCamelCase(excludeSCBErrorStatus),
        SCBError,
        isSCBUser,
        lastUpdate: new Date(),
      } as systemStatusStateCommon.SystemStatusType
      setSystemStatus(updatedSystemStatus)

      return updatedSystemStatus
    } catch (error) {
      return null
    }
  }

  const collectSystemStatus = (data: any) => {
    const systemStatusFlag: SystemStatusFlag = {
      DepositError: false,
      FreeStyle: false,
      FreeStyleSCB: false,
      FreeStyleText: '',
      FreeStyleTextSCB: '',
      FriendInvite: false,
      GameError: false,
      KioskError: false,
      Register: false,
      SCBError: false,
      UnderConstruction: false,
      UnderConstructionText: '',
      Valentine: false,
      WithdrawError: false,
      UserActiveStatus: false,
    }
    let systemStatusResponse: SystemStatusResponse = {
      ...systemStatusFlag,
      Inbox: [],
      Toast: { ...systemStatusFlag },
    }
    systemStatusResponse = data
    const { SCBError, Inbox, Toast, ...excludeSCBErrorStatus } =
      systemStatusResponse
    const isSCBUser =
      (profile as any)?.data?.bank_account_list[0].bank_code === 'SCB'
    const allInbox = Inbox?.map(mapToInboxItem)?.slice()?.reverse()
    const { system } = inboxSelector(allInbox || [])
    if (system.length > 0) {
      setInboxSystemStatus((current) => {
        return {
          ...current,
          inboxs: insertAndUpdateInboxs(current.inboxs, system),
        }
      })
    }
    const updatedSystemStatus = {
      ...excludeSCBErrorStatus,
      toast: systemStatusResponse.Toast,
      SCBError,
      isSCBUser,
      lastUpdate: new Date(),
    } as any
    setSystemStatus(updatedSystemStatus)
    return updatedSystemStatus
  }

  return {
    getSystemStatusMessage,
    refreshSystemStatus,
    collectSystemStatus,
  }
}

export default useLegacySystemStatus
