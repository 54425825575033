import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRecoilState } from 'recoil'
import {
  API_URL,
  BO_API_URL,
  PIGSPIN_API_URL,
  QR_PAYMENT_API_URL,
} from '@pig-common/models/buildtime-constant'
import { authStateCommon } from '@pig-common/recoils'
import { BaseApiResponse } from '@pig-common/services/service.type'

const handleResponse = (response: AxiosResponse<any>) => response
const handleErrorResponse = (error: AxiosError<BaseApiResponse<any>>) =>
  error.response

export const useAxios = () => {
  const [auth] = useRecoilState(authStateCommon.authState)
  const phpApiInstance = axios.create({
    baseURL: API_URL,
    // timeout: 5000,
  })

  const boApiInstance = axios.create({
    baseURL: BO_API_URL,
    headers: {
      authorisation: auth.cfid,
    },
  })
  boApiInstance.interceptors.response.use(handleResponse, handleErrorResponse)

  const pigspinApiInstance = axios.create({
    baseURL: PIGSPIN_API_URL,
    headers: {
      authorisation: auth.cfid,
    },
    // timeout: 5000,
  })
  pigspinApiInstance.interceptors.response.use(
    handleResponse,
    handleErrorResponse,
  )

  const qrPaymentApiInstance = axios.create({
    baseURL: QR_PAYMENT_API_URL,
    // timeout: 7500,
  })
  qrPaymentApiInstance.interceptors.response.use(
    handleResponse,
    handleErrorResponse,
  )
  return {
    phpApiInstance,
    boApiInstance,
    pigspinApiInstance,
    qrPaymentApiInstance,
  }
}
