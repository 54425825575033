/**
 * IMPROVE: We can cut "Level" word from API
 * Cut "Level" word from API and lowerCase it
 * Ex. "Bronze Level" to "bronze"
 */
const defaultTierKey = ['userTier', 'tier']
export const tierMapper = <T>(data?: any, tierKeys?: string[]): T => {
  if (Array.isArray(data)) {
    return data?.map((v) => tierMapper(v === null ? undefined : v)) as T
  } else if (data != null && data?.constructor === Object) {
    return Object.keys(data).reduce((result, key) => {
      return {
        ...result,
        ...((tierKeys ?? defaultTierKey).includes(key)
          ? { [key]: handleUserTier(data[key]) }
          : { [key]: tierMapper(data[key] === null ? undefined : data[key]) }),
      }
    }, {}) as T
  }
  return data
}

const handleUserTier = (userTier: string) => {
  return userTier?.toLowerCase()?.replace('level', '')?.trim()
}
