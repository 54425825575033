export const breakPoints = {
  xs: '320px',
  sm: '375px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
}

export const screen = {
  xs: `(min-width: ${breakPoints.xs})`,
  sm: `(min-width: ${breakPoints.sm})`,
  md: `(min-width: ${breakPoints.md})`,
  lg: `(min-width: ${breakPoints.lg})`,
  xl: `(min-width: ${breakPoints.xl})`,
}
