import { ReactNode } from 'react'
import cx from 'classnames'
import { StaticImageData } from 'next/image'
import Link from 'next/link'
import { RightOutlined } from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'
import styles from './index.module.scss'

export interface IContactUsObjectProps {
  key: 'email' | string
  title: string
  icon?: ReactNode | null
  color?: string
  text?: string
  image?: StaticImageData | string
  link?: string
  onClick?: () => void | null
}

export interface IContactUsProps {
  contacts: IContactUsObjectProps[]
  placeholderImage: StaticImageData | string
  className?: string
}

export function ContactUs({
  contacts = [],
  placeholderImage,
  className,
}: IContactUsProps) {
  return (
    <div className={cx(styles.container, className)}>
      {contacts.map((cts) => (
        <Link
          key={cts.key}
          href={cts.key === 'email' ? `mailto:${cts?.link}` : cts?.link || '#'}
          {...(cts.onClick ? { onClick: cts?.onClick } : {})}
          className={styles.box}
          data-testid="uikit-contactus-link"
        >
          {cts?.image ? (
            <Image
              src={cts?.image}
              alt={cts.title}
              width={36}
              height={36}
              placeholderImage={placeholderImage}
              className={styles.img}
              data-testid="uikit-contactus-img"
            />
          ) : (
            <div
              className={styles.icon}
              style={{ backgroundColor: cts?.color }}
            >
              {cts?.icon}
            </div>
          )}
          <span data-testid="uikit-contactus-title" className={styles.title}>
            {cts.title}
          </span>
          <span data-testid="uikit-contactus-text" className={styles.text}>
            {cts?.text ?? cts.title}
          </span>
          <RightOutlined className={styles['right-icon']} />
        </Link>
      ))}
    </div>
  )
}
