import { atom } from 'recoil'

import { KEYS } from '../keys'
import { Notifications } from '@pig-common/hooks/useNotification'

export const initialValue: Notifications = {
  dailyQuest: false,
}

export const notificationState = atom<Notifications>({
  key: KEYS.NOTIFICATION,
  default: initialValue,
})
