import { ConfigProvider as AntdConfigProvider } from 'antd'
import { ConfigConsumerProps as AntdConfigProviderProps } from 'antd/es/config-provider'
import { defaultTheme } from '@pig-frontend/uikit'
import { ThemeConfig } from 'antd/es/config-provider/context'

export interface ConfigProviderProps extends Partial<AntdConfigProviderProps> {
  children?: React.ReactNode
  theme?: ThemeConfig
}

export function ConfigProvider({
  children,
  theme,
  ...props
}: ConfigProviderProps) {
  return (
    <AntdConfigProvider theme={theme || defaultTheme} {...props}>
      {children}
    </AntdConfigProvider>
  )
}

export default ConfigProvider
