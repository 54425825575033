import { authStateCommon, profileStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { IProfileData } from '@pig-common/types/Profile.type'
import {
  getProfile,
  GetProfileParams,
  ProfileRequest,
} from '@pig-common/services/profile/profile'
import { useCookies } from 'react-cookie'
import { COOKIES_KEY } from '@pig-common/utils/cookies'

interface IProfileHook {
  params?: GetProfileParams
  select?: (data: IProfileData) => IProfileData
  enabled?: boolean
}

export default function useProfile({ params, select, enabled }: IProfileHook) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { userUID } = useRecoilValue(authStateCommon.authState)
  const setProfileState = useSetRecoilState(profileStateCommon.profileState)
  const [cookies] = useCookies<string>([COOKIES_KEY.CFID])
  const req: ProfileRequest = {
    ...params,
    query_by: 'USER_CODE',
    user_code: userUID,
    phone_number: params?.phone_number || '',
    phone_number_pattern: '********00',
    id_card_pattern: '*********0000',
    back_id_card_pattern: '**********00',
    bank_account_number_10_digits_pattern: '******0000',
    bank_account_number_12_digits_pattern: '********0000',
    token: cookies.cfid,
  }

  return useQuery({
    queryKey: ['getProfile', params],
    queryFn: () => getProfile(req),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select && data ? select(data) : (data as IProfileData)),
    onSuccess: (data: IProfileData) => {
      setProfileState(data)
    },
  })
}
