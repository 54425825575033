import { ReactNode } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export interface NavigationItem {
  label: string
  icon: ReactNode
  link?: string
  showBadge: boolean
}

interface NavigationBarProps {
  items?: NavigationItem[]
}

export function NavigationBar({ items = [] }: NavigationBarProps) {
  const router = useRouter()

  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div key={`navigation-${item.label}`} className={styles.item}>
          <div
            aria-hidden
            role="button"
            {...(item.link && {
              onClick: () => router.push(item?.link || '#'),
            })}
            className={cx(styles.button, {
              [styles.active]: router.asPath === item.link,
            })}
          >
            {item.icon}
            {item?.showBadge && <div className={styles.badge} />}
            <span
              className={styles.label}
              data-testid="uikit-navigationbar-label"
            >
              {item.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default NavigationBar
