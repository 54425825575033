import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

interface IClaimParams {
  customerCode?: string
  losingStreakUid?: string
}

async function claim({
  customerCode,
  losingStreakUid,
}: IClaimParams): Promise<CommonResponse<null>> {
  return await api.post('/v1/wallet/casino/losing-streak/claim-losing-streak', {
    customer_code: customerCode,
    losing_streak_uid: losingStreakUid,
  })
}

export const key = {
  claim: 'LOSE_STREAK_CLAIM',
}

export { claim }
