import cx from 'classnames'
import { isValidElement, ReactNode, useState } from 'react'
import { FloatButton } from 'antd'
import { HeadPhoneIcon } from '@pig-frontend/uikit/constants/images'
import { StaticImageData } from 'next/image'
import { Button, Image } from '@pig-frontend/uikit'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import styles from './index.module.scss'

export interface IFloatingContactUsObjectProps {
  key: 'email' | string
  icon: StaticImageData | string | ReactNode
  color?: string
  link?: string
  action?: () => void
}

export interface IFloatingContactUsProps {
  contacts: IFloatingContactUsObjectProps[]
  className?: string
}

export function FloatingContactUs({
  contacts,
  className,
}: IFloatingContactUsProps) {
  const router = useRouter()
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClick = (link?: string, action?: () => void) => {
    if (link && !isEmpty(link)) {
      router.push(link)
    } else if (action) {
      action()
    }
    setOpen(false)
  }

  return (
    <FloatButton.Group
      open={isOpen}
      onOpenChange={setOpen}
      trigger="click"
      icon={<HeadPhoneIcon />}
      className={cx(styles.container, className)}
    >
      {contacts.map((ct) => {
        const Icon = ct?.icon
        return (
          <Button
            key={ct.key}
            style={{ backgroundColor: ct.color }}
            className={cx(styles['float-btn'], {
              [styles.hide]: !isOpen,
            })}
            onClick={() => handleClick(ct?.link, ct?.action)}
            icon={
              isValidElement(Icon) ? (
                <div className={styles.icon}>{ct?.icon as ReactNode}</div>
              ) : (
                <Image
                  src={ct?.icon as StaticImageData | string}
                  alt={`contact us - ${ct.key}`}
                  width={28}
                  height={28}
                />
              )
            }
          />
        )
      })}
    </FloatButton.Group>
  )
}
