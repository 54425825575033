import { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { screen } from '@pig-common/utils/responsive'

const StyleSectionHeader = {
  Section: styled.header`
    width: 100%;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme['color-greyscale-600']};
  `,
  HeaderContent: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Title: styled.h3`
    display: block;
    font-weight: 800;
    font-size: 34px;
    line-height: 34px;
    align-items: center;
    color: ${({ theme }) => theme['color-greyscale-600']};
    @media ${screen.md} {
      font-size: 46px;
    }
  `,
  SubTitle: styled.h4`
    display: block;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme['color-greyscale-500']};
  `,
  Content: styled.header`
    > * {
      display: flex;
      align-items: center;
    }
    .anticon,
    svg {
      font-size: 14px !important;
    }
  `,
}

const SectionHeader = ({
  sectionId,
  title,
  subTitle,
  content,
}: {
  sectionId: string
  title: string | JSX.Element
  subTitle?: string | JSX.Element
  content?: ReactNode
}) => {
  const headerTitle = useMemo(() => {
    const isString = typeof title === 'string'
    if (isString && title === '') return null
    if (isString)
      return (
        <StyleSectionHeader.Title data-testid={`${sectionId}-header-title`}>
          {title}
        </StyleSectionHeader.Title>
      )
    return title
  }, [title])
  return (
    <StyleSectionHeader.Section>
      <StyleSectionHeader.HeaderContent>
        {headerTitle}
        {content && (
          <StyleSectionHeader.Content
            data-testid={`${sectionId}-header-content`}
          >
            {content}
          </StyleSectionHeader.Content>
        )}
      </StyleSectionHeader.HeaderContent>
      {subTitle && (
        <StyleSectionHeader.SubTitle
          data-testid={`${sectionId}-header-subtitle`}
        >
          {subTitle}
        </StyleSectionHeader.SubTitle>
      )}
    </StyleSectionHeader.Section>
  )
}

export default SectionHeader
