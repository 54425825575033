import { Radio as AntdRadio, RadioGroupProps, RadioProps } from 'antd'
import cx from 'classnames'
import styles from './index.module.scss'

export function Radio({ className, ...props }: RadioProps) {
  return <AntdRadio {...props} className={cx(styles.radio, className)} />
}

Radio.Group = ({ className, ...props }: RadioGroupProps) => {
  return (
    <AntdRadio.Group
      {...props}
      className={cx(styles['radio-group'], className)}
    />
  )
}
