import { ReactNode, useEffect } from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { Badge } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import {
  Divider,
  ProfileAvatar,
  ProfileAvatarTypes,
  Image,
} from '@pig-frontend/uikit'
import { useRouter } from 'next/router'
import { VipIcon } from '@pig-frontend/uikit/constants/images'
import styles from './index.module.scss'

interface IMenus {
  key: string
  title: string
  icon: ReactNode
  link?: string
  priority?: 'primary' | 'secondary'
  hasBadge?: boolean
}

export interface IHamburgerProps {
  menus: IMenus[]
  logout: {
    title?: string
    icon: ReactNode
    onClick?: () => void
  }
  profile: {
    userName?: string
    /** Image component that replaced placeholder */
    avatarName?: ProfileAvatarTypes
    profileLink?: string
    verifiedAccountLink?: string
    isVerified?: boolean
    verifyIcon?: ReactNode
    verifyText?: string
    verifyLink?: string
    extra?: ReactNode | string
    showProgressBar?: boolean
    vip?: ReactNode | string
    exp?: ReactNode | string
  }
  guild?: {
    iconUrl?: string
    bannerUrl?: string
    role?: 'owner' | 'member' | 'admin'
    guildId?: string
    guildName?: string
    description?: string
  }
  vip?: {
    link?: string
  }
  balanceNode?: ReactNode | null
  onCloseDrawer?: () => void
}

export function Hamburger({
  profile,
  guild,
  vip,
  menus = [],
  logout,
  onCloseDrawer,
}: IHamburgerProps) {
  const router = useRouter()
  const handleMenuClicked = (action?: () => void) => {
    action && action()
    onCloseDrawer && onCloseDrawer()
  }

  const handleClickLink = (action: (...args: any[]) => void) => {
    action()
    onCloseDrawer && onCloseDrawer()
  }
  useEffect(() => {
    onCloseDrawer && onCloseDrawer()
  }, [router.pathname])

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <div className={styles.detail}>
          <div
            className={styles.avatar}
            {...(profile?.profileLink
              ? {
                  role: 'button',
                  'aria-hidden': true,
                  onClick: () =>
                    handleClickLink(() =>
                      router.push(profile?.profileLink || ''),
                    ),
                }
              : {})}
          >
            <ProfileAvatar
              name={profile?.avatarName}
              imgClassName={styles.img}
              width={55}
              height={55}
            />
            {guild?.iconUrl && (
              <Image
                src={guild?.iconUrl || ''}
                width={28}
                height={28}
                alt="guild"
                className={styles.guild}
              />
            )}
          </div>
          <div className={styles.vip}>{profile?.vip}</div>

          <div className={styles.extra}>
            <Link
              href={vip?.link ?? '#'}
              onClick={() => handleMenuClicked()}
              data-testid="uikit-hamburger-menu-vip"
            >
              <VipIcon />
            </Link>
            {profile?.extra}
          </div>
        </div>

        <div className={styles['level-progress']}>{profile.exp}</div>
        <div
          className={styles.verify}
          {...(!profile?.isVerified
            ? {
                role: 'button',
                'aria-hidden': true,
                onClick: () =>
                  handleClickLink(() =>
                    router.push(profile?.verifyLink || '#'),
                  ),
              }
            : {})}
        >
          {profile?.verifyIcon}
          <span className={styles.text}>{profile?.verifyText}</span>
          {!profile?.isVerified && <RightOutlined className={styles.icon} />}
        </div>
      </div>

      <Divider margin={12} />

      <div className={styles.menus}>
        {menus.map((menu) => (
          <Link
            href={menu?.link ?? '#'}
            key={menu?.key}
            onClick={() => handleMenuClicked()}
            data-testid="uikit-hamburger-menu"
            className={styles.link}
          >
            <div
              className={cx(
                styles.menu,
                menu?.priority && styles[menu?.priority],
              )}
            >
              <Badge dot={menu.hasBadge}>{menu?.icon}</Badge>
              <span>{menu?.title}</span>
            </div>
          </Link>
        ))}
      </div>

      <Divider margin={16} />

      <div
        role="button"
        aria-hidden
        className={styles.signout}
        onClick={() => handleMenuClicked(logout.onClick)}
      >
        {logout?.icon}
        {logout?.title ?? 'ออกจากระบบ'}
      </div>
    </div>
  )
}
