import { atom, selectorFamily } from 'recoil'

import { UncapitalizeObjectKeys } from '@pig-common/utils/common'
import { SystemStatusResponse } from '@pig-common/hooks/useLegacySystemStatus'

export enum ERROR_STATUS_VALUE {
  CLOSE_REGISTER = -3,
  INACTIVE_USER = -2,
  UNDER_CONSTRUCTION = -1,
  IGNORE = 0,
  NONE = 1,
  DEPOSIT = 2, // depositError
  WITHDRAW = 3, // withdrawError
  GAME = 5, // gameError
  DEPOSIT_AND_WITHDRAW = DEPOSIT * WITHDRAW,
  DEPOSIT_AND_GAME = DEPOSIT * GAME,
  WITHDRAW_AND_GAME = WITHDRAW * GAME,
  ALL = DEPOSIT * WITHDRAW * GAME,
  SCB_DEPOSIT = 7, // SCBError
  SCB_DEPOSIT_AND_WITHDRAW = SCB_DEPOSIT * WITHDRAW,
  SCB_DEPOSIT_AND_GAME = SCB_DEPOSIT * GAME,
  SCB_DEPOSIT_AND_WITHDRAW_AND_GAME = SCB_DEPOSIT * WITHDRAW * GAME,
}

export type SystemStatusType = UncapitalizeObjectKeys<
  Omit<SystemStatusResponse, 'SCBError'>
> & {
  SCBError: boolean
  isSCBUser: boolean
  lastUpdate: Date
}

export const key = {
  systemStatusState: 'COMMON/SYSTEM_STATUS',
}

export const defaultSystemStatusState: SystemStatusType | any = {
  userActiveStatus: false,
  depositError: false,
  depositErrorText: '',
  withdrawError: false,
  withdrawErrorText: '',
  gameError: false,
  SCBError: false,
  friendInvite: false,
  register: false,
  registerText: '',
  kioskError: false,
  underConstruction: false,
  underConstructionText: '',
  freeStyle: false,
  freeStyleText: '',
  freeStyleSCB: false,
  freeStyleTextSCB: '',
  valentine: false,
  isSCBUser: false,
  lastUpdate: new Date(),
}

export const systemStatusState = atom<SystemStatusType>({
  key: key.systemStatusState,
  default: defaultSystemStatusState,
})

export const systemStatusToastInitialValue = {
  currentMessage: '',
}

export const calculatedSystemStatusState = selectorFamily<
  ERROR_STATUS_VALUE,
  { isAuthen: boolean }
>({
  key: 'currentSystemStatus',
  get:
    ({ isAuthen }) =>
    ({ get }) => {
      const {
        gameError,
        SCBError,
        withdrawError,
        depositError,
        underConstruction,
        userActiveStatus,
        register,
        isSCBUser,
      } = get(systemStatusState)
      if (underConstruction) return ERROR_STATUS_VALUE.UNDER_CONSTRUCTION
      if (userActiveStatus === false) return ERROR_STATUS_VALUE.INACTIVE_USER
      if (register === true && !isAuthen) {
        return ERROR_STATUS_VALUE.CLOSE_REGISTER
      }

      const gameErrorValue = gameError
        ? ERROR_STATUS_VALUE.GAME
        : ERROR_STATUS_VALUE.NONE
      let scbErrorValue =
        isSCBUser && SCBError
          ? ERROR_STATUS_VALUE.SCB_DEPOSIT
          : ERROR_STATUS_VALUE.NONE
      const withdrawErrorValue = withdrawError
        ? ERROR_STATUS_VALUE.WITHDRAW
        : ERROR_STATUS_VALUE.NONE
      let depositErrorValue = depositError
        ? ERROR_STATUS_VALUE.DEPOSIT
        : ERROR_STATUS_VALUE.NONE

      // NOTE : SCB user Special case
      if (isSCBUser && SCBError && depositError) {
        depositErrorValue = ERROR_STATUS_VALUE.DEPOSIT
        scbErrorValue = ERROR_STATUS_VALUE.NONE
      }

      const totalValue =
        gameErrorValue * scbErrorValue * withdrawErrorValue * depositErrorValue

      return totalValue as ERROR_STATUS_VALUE
    },
})
