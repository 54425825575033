export interface IAuthState {
  cfid?: string
  customerCode?: string
  name?: string
  gameToken?: string
  isFirstLogin?: boolean
  isRenewPin?: boolean
  isRegisterLineSuccess?: boolean
  isLoginPhoneSuccess?: boolean
  userUID?: string
  authInterceptor?: number
}

export enum authCookieKey {
  cfid = 'cfid',
  customerCode = 'customer_code',
  userUID = 'uid',
}

export interface IAuthCookieDataProps {
  cfid?: string
  uid?: string
  customerCode?: string
}
