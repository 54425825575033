import { useState, useEffect } from 'react'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  NavigationBarMainIcon,
  NavigationBarPigSpinIcon,
} from '@pig-casino/constants/images'
import { CloseOutlined } from '@ant-design/icons'
import styles from './index.module.scss'
import useAppSwitcher from '@pig-casino/hooks/useAppSwitcher'

export type AppProduct = 'PIGSPIN' | 'PIGBET'

export type IAppSwitcherProps = {
  onChange?: (isOpen: boolean) => void
  onSwitch?: (app: AppProduct) => void
  className?: string
}

const AppSwitcher: React.FunctionComponent<IAppSwitcherProps> = ({
  onChange,
  className,
  onSwitch,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isCloseButtonActive, setCloseButtonActive] = useState<boolean>(false)
  const [, { handleSwitch }] = useAppSwitcher()

  useEffect(() => {
    if (onChange) onChange(isOpen)
  }, [isOpen])

  return (
    <div className={cx(styles.container, className)}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.9 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className={styles['background-cover']}
          />
        )}
      </AnimatePresence>
      <div
        aria-hidden
        role="button"
        onClick={() => setIsOpen(!isOpen)}
        className={styles['main-icon']}
      >
        {isOpen ? (
          <div
            aria-hidden
            role="button"
            className={cx(styles['close-icon'], {
              [styles.active]: isCloseButtonActive,
            })}
            onTouchStart={() => setCloseButtonActive(true)}
            onTouchEnd={() => setCloseButtonActive(false)}
          >
            <CloseOutlined />
          </div>
        ) : (
          <NavigationBarMainIcon />
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onClick={handleSwitch}
            className={styles['popup-container']}
            initial={{ opacity: 0, top: 0 }}
            animate={{ opacity: 0.9, top: -52 }}
            exit={{ opacity: 0, top: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
          >
            <span className={styles.label}>สล็อต</span>
            <NavigationBarPigSpinIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AppSwitcher
