import snakeCase from 'lodash/snakeCase'

/**
 *
 * NOTE: convert key in every level of object to snakeCase.
 * And value will always convert null to undefined. (change this if doesn't need it)
 *
 */
export const snakeCaseKeys = (
  obj: Record<string, any>,
): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeCaseKeys(v === null ? undefined : v))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: snakeCaseKeys(
          obj[key] === null ? undefined : obj[key],
        ),
      }),
      {},
    )
  }
  return obj
}
