import getConfig from 'next/config'

export enum ENV_ENUM {
  DEV = 'dev',
  QA = 'qa',
  STAGING = 'staging',
  PRE_PROD = 'pre-prod',
  PROD = 'prod',
}

const config = getConfig()
const publicRuntimeConfig = config?.publicRuntimeConfig
/**
 * Client side
 * NOTE : use publicRuntimeConfig here
 */
export const ENV_TYPE: ENV_ENUM = publicRuntimeConfig?.environment

export const TOTAL_PIN_CONFIG = publicRuntimeConfig?.totalPinAmount as number
export const PROMOTION_TEXT_SEPARATOR_CONFIG =
  publicRuntimeConfig?.promotionDataLineSeparator as string | RegExp

export const MAXIMUM_DISPLAYABLE_BALANCE =
  publicRuntimeConfig?.maxMoneyBalance as number
export const MINIMUM_DISPLAYABLE_BALANCE =
  publicRuntimeConfig?.minMoneyBalance as number

/**
 * Server side
 * NOTE : use serverRuntimeConfig here
 */
