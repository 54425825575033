import { api } from '@pig-common/api/api-creators'
import { CamelCaseKeys, CommonResponse } from '@pig-common/types/Api.type'
import { IAuthState } from '@pig-common/types/Authentication.type'
import {
  CreateRainData,
  CreateRainPayload,
  GrabRainData,
  GrabRainPayload,
  RainQueueItem,
  RainSetting,
  SendTipData,
  SendTipPayload,
  TipSetting,
} from '@pig-common/types/Rain.type'

async function getTimeZoneHour(): Promise<number> {
  return await api.get('/v1/loyalty/timezone_hour')
}

async function getRainQueue(
  auth: IAuthState,
): Promise<CommonResponse<RainQueueItem[]>> {
  return await api.get(
    `/v1/loyalty/rain/${auth.userUID}/${auth.customerCode}/queue`,
  )
}

async function getRainSetting(
  auth: IAuthState,
): Promise<CommonResponse<CamelCaseKeys<RainSetting>>> {
  return await api.get(`/v1/loyalty/rain/${auth.customerCode}/setting`)
}

async function getTipSetting(
  auth: IAuthState,
): Promise<CommonResponse<CamelCaseKeys<TipSetting>>> {
  return await api.get(`/v1/loyalty/tip/${auth.customerCode}/setting`)
}

async function createRain(
  payload: CreateRainPayload,
): Promise<CommonResponse<CamelCaseKeys<CreateRainData>>> {
  return await api.post('/v1/loyalty/rain/create', payload)
}

async function grabRain(
  payload: GrabRainPayload,
): Promise<CommonResponse<CamelCaseKeys<GrabRainData>>> {
  return await api.post('/v1/loyalty/rain/receive', payload)
}

async function sendTip(
  payload: SendTipPayload,
): Promise<CommonResponse<CamelCaseKeys<SendTipData>>> {
  return await api.post('/v1/loyalty/tip/sent', payload)
}

export {
  getTimeZoneHour,
  getRainQueue,
  getRainSetting,
  getTipSetting,
  createRain,
  grabRain,
  sendTip,
}
