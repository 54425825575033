import { IFooterProps } from '@pig-frontend/uikit'
import {
  kbankImage,
  ktbImage,
  scbImage,
  bayImage,
  bblImage,
  //   btcImage,
  gsbImage,
  kkpImage,
  lhImage,
  ttbImage,
  uobImage,
  promptpayImage,
  truewalletImage,
} from '@pig-casino/constants/images'

export const banks: IFooterProps['banks'] = [
  {
    key: 'kbank',
    name: 'kbank',
    img: kbankImage,
  },
  {
    key: 'scb',
    name: 'scb',
    img: scbImage,
  },
  {
    key: 'ktb',
    name: 'ktb',
    img: ktbImage,
  },
  {
    key: 'bbl',
    name: 'bbl',
    img: bblImage,
  },
  {
    key: 'bay',
    name: 'bay',
    img: bayImage,
  },
  {
    key: 'tuewallet',
    name: 'tuewallet',
    img: truewalletImage,
  },
  {
    key: 'promptpay',
    name: 'promptpay',
    img: promptpayImage,
  },
  {
    key: 'ttb',
    name: 'ttb',
    img: ttbImage,
  },
  {
    key: 'kkp',
    name: 'kkp',
    img: kkpImage,
  },
  {
    key: 'gsb',
    name: 'gsb',
    img: gsbImage,
  },
  {
    key: 'lh',
    name: 'lh',
    img: lhImage,
  },
  {
    key: 'uob',
    name: 'uob',
    img: uobImage,
  },
  //   {
  //     key: 'btc',
  //     name: 'btc',
  //     img: btcImage,
  //   },
]
