import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

export interface IVerifyTokenResponseData {
  success?: boolean
}

async function verify(): Promise<CommonResponse<IVerifyTokenResponseData>> {
  return await api.get('/v1/user/token/verify')
}

export interface IRevokeTokenResponseData {
  success?: boolean
}

async function revoke(): Promise<CommonResponse<IRevokeTokenResponseData>> {
  return await api.put('/v1/user/token/revoke')
}

export const key = {
  verify: 'TOKEN_VERIFY',
  revoke: 'TOKEN_REVOKE',
}

export { verify, revoke }
