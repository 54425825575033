import { atom } from 'recoil'
import { KEYS } from '../keys'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export interface ChampionState {
  triggered: boolean
}

export const initialValue: ChampionState = {
  triggered: false,
}

export const championState = atom<ChampionState>({
  key: KEYS.CHAMPION,
  default: initialValue,
  effects: [persistAtom],
})
