import { atom } from 'recoil'

export type BackButtonState = {
  onBack: (() => void) | null | number
}

export const key = {
  backButtonState: 'COMMON/BACK_BUTTON',
}

export const defaultBackButtonState: BackButtonState = {
  onBack: null,
}

export const backButtonState = atom<BackButtonState>({
  key: key.backButtonState,
  default: defaultBackButtonState,
})
