import { RightOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'

const UserKyc = ({ verified }: { verified: boolean }) => {
  const router = useRouter()

  return (
    <div className="ps-user-kyc">
      {!verified ? (
        <button
          className="ps-button-clean ps-user-kyc__button"
          onClick={() => router.push('/profile/set-kyc')}
        >
          <div className="ps-user-kyc__label">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.65 21.5L5.75 18.25L1.975 17.475L2.4 13.8L0 11L2.4 8.225L1.975 4.55L5.75 3.775L7.65 0.5L11 2.05L14.35 0.5L16.275 3.775L20.025 4.55L19.6 8.225L22 11L19.6 13.8L20.025 17.475L16.275 18.25L14.35 21.5L11 19.95L7.65 21.5ZM8.325 19.525L11 18.4L13.75 19.525L15.425 17.025L18.35 16.275L18.05 13.3L20.075 11L18.05 8.65L18.35 5.675L15.425 4.975L13.7 2.475L11 3.6L8.25 2.475L6.575 4.975L3.65 5.675L3.95 8.65L1.925 11L3.95 13.3L3.65 16.325L6.575 17.025L8.325 19.525ZM9.925 14.325L15.6 8.7L14.475 7.675L9.925 12.175L7.55 9.7L6.4 10.825L9.925 14.325Z"
                fill="#2A2A2E"
              />
            </svg>
            &nbsp; ยืนยันตัวตนเพื่อรับฟรีเครดิต
          </div>
          <RightOutlined size={16} />
        </button>
      ) : (
        <div className="ps-user-kyc__button">
          <div className="ps-user-kyc__label">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.65 21.5L5.75 18.25L1.975 17.475L2.4 13.8L0 11L2.4 8.225L1.975 4.55L5.75 3.775L7.65 0.5L11 2.05L14.35 0.5L16.275 3.775L20.025 4.55L19.6 8.225L22 11L19.6 13.8L20.025 17.475L16.275 18.25L14.35 21.5L11 19.95L7.65 21.5ZM9.925 14.325L15.6 8.7L14.475 7.675L9.925 12.175L7.55 9.7L6.4 10.825L9.925 14.325Z"
                fill="#408558"
              />
            </svg>
            &nbsp; ยืนยันตัวตนแล้ว
          </div>
        </div>
      )}
    </div>
  )
}

export default UserKyc
