import { useQuery } from '@tanstack/react-query'
import { tokenApi } from '@pig-common/api'
import { CommonResponse } from '@pig-common/types/Api.type'

interface ITokenVerifyProps<SelectReturnType> {
  select?: (
    data: CommonResponse<tokenApi.IVerifyTokenResponseData>,
  ) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useVerifyToken<
  SelectReturnType = tokenApi.IVerifyTokenResponseData,
>({ select, enabled }: ITokenVerifyProps<SelectReturnType>) {
  return useQuery({
    queryKey: [tokenApi.key.verify],
    queryFn: () => tokenApi.verify(),
    enabled,
    select: (data) => (select ? select(data) : data?.data || {}),
  })
}
