export const APP_ENV = process.env.ENV_TYPE as 'pre-prod' | 'prod' | undefined
export const NODE_ENV = process.env.NODE_ENV
export const API_BASE = process.env.NEXT_PUBLIC_API_BASE_URL
export const API_BO = process.env.NEXT_PUBLIC_API_BO
export const FACEBOOK_URL = process.env.NEXT_PUBLIC_FACEBOOK_OFFICIAL_URL
export const CLOUD_STORAGE_CDN = process.env.NEXT_PUBLIC_CLOUD_STORAGE_CDN
export const PRODUCT_NAME = process.env.NEXT_PUBLIC_PRODUCT_NAME
export const EVO_GAME_URL = process.env.NEXT_PUBLIC_EVO_GAME_URL
export const EVO_GAME_WEBSOCKET = process.env.NEXT_PUBLIC_EVO_GAME_WEBSOCKET
export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID
export const PIGSPIN_URL = process.env.NEXT_PUBLIC_PIGSPIN_URL
export const SERVER_REVALIDATE = process.env.NEXT_PUBLIC_REVALIDATE_SERVER_DATA
export const GIT_TAG_VERSION = process.env.NEXT_PUBLIC_GIT_TAG_VERSION // Assign in build command in package.json
