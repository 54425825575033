import { AxiosRequestConfig } from 'axios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import {
  BaseApiResponse,
  BaseResponse,
} from '@pig-common/services/service.type'
import { IProfileData } from '@pig-common/types/Profile.type'
import { camelizeKeys } from '@pig-frontend/utils'

export enum REGISTER_TYPE {
  NORMAL = 'NORMAL',
  ALIEN = 'ALIEN',
  NO_DOB = 'NO_DOB',
  OTHERS = 'OTHERS',
}

type ProfileResponse = BaseResponse & {
  data: IProfileData
}

export type GetProfileParams = {
  query_by: string
  id_card?: string
  user_code?: string
  phone_number?: string
  phone_number_pattern: string
  id_card_pattern: string
  back_id_card_pattern: string
  bank_account_number_10_digits_pattern: string
  bank_account_number_12_digits_pattern: string
}

export type ProfileRequest = GetProfileParams & {
  token: string
}

export const getProfile = async (
  request: ProfileRequest,
): Promise<IProfileData> => {
  const { token, ...params } = request
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/user/me/profile${
      params ? `?${new URLSearchParams(params).toString()}` : ''
    }`,
    method: 'GET',
    headers: {
      authorisation: token,
    },
  }
  const result = await fetchWithJSON<ProfileResponse>(
    pigspinApiInstance,
    requestConfig,
  )
  return camelizeKeys(result.data) as IProfileData
}

export type CheckPsCodeResponse = BaseApiResponse<any>

export const checkPsCode = async (
  psCode: string,
): Promise<CheckPsCodeResponse> => {
  return fetchWithJSON<CheckPsCodeResponse>(pigspinApiInstance, {
    url: `v1/user/player-name/${psCode}/check`,
    method: 'GET',
  })
}
