import { IHamburgerProps } from '@pig-frontend/uikit'
import {
  HamburgerMenuHomeIcon,
  HamburgerMenuWalletIcon,
  HamburgerMenuPromotionsIcon,
  HamburgerMenuAffilliateIcon,
  HamburgerMenuGamesIcon,
  HamburgerMenuContactUsIcon,
  HamburgerMenuFaqIcon,
  HamburgerMenuSettingIcon,
  HamburgerMenuChampionIcon,
  HamburgerMenuQuestIcon,
  HamburgerMenuRedeemIcon,
} from '@pig-casino/constants/images'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { pageLink } from '@pig-casino/constants/page-name'

export const hamburgerMenus: IHamburgerProps['menus'] = [
  {
    key: 'wallet',
    title: 'เติม / ถอน',
    icon: <HamburgerMenuWalletIcon />,
    link: pageLinkCommon.wallet,
    priority: 'primary',
  },
  {
    key: 'home',
    title: 'หน้าแรก',
    icon: <HamburgerMenuHomeIcon />,
    link: pageLinkCommon.lobby,
    priority: 'secondary',
  },
  {
    key: 'promotions',
    title: 'โปรโมชั่น',
    icon: <HamburgerMenuPromotionsIcon />,
    link: pageLinkCommon.promotions,
  },
  {
    key: 'affilliate',
    title: 'แนะนำเพื่อน',
    icon: <HamburgerMenuAffilliateIcon />,
    link: pageLinkCommon.affiliate,
  },
  {
    key: 'games',
    title: 'เกมทั้งหมด',
    icon: <HamburgerMenuGamesIcon />,
    link: pageLink.games.index,
  },
  {
    key: 'champion',
    title: 'แชมป์เปี้ยน',
    icon: <HamburgerMenuChampionIcon />,
    link: pageLinkCommon.champion,
  },
  {
    key: 'daily-quest',
    title: 'ภารกิจ',
    icon: <HamburgerMenuQuestIcon />,
    link: pageLinkCommon.dailyQuests,
  },
  {
    key: 'pig-shop',
    title: 'แลกรางวัล',
    icon: <HamburgerMenuRedeemIcon />,
    link: pageLinkCommon.pigShop,
  },
  {
    key: 'contact-us',
    title: 'ติดต่อเรา',
    icon: <HamburgerMenuContactUsIcon />,
    link: pageLink.contactUs,
  },
  {
    key: 'faq',
    title: 'ถาม-ตอบ',
    icon: <HamburgerMenuFaqIcon />,
    link: pageLinkCommon.faq,
  },
  {
    key: 'setting',
    title: 'ตั้งค่าบัญชี',
    icon: <HamburgerMenuSettingIcon />,
    link: pageLinkCommon.profile.home,
  },
]
