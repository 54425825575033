import { AxiosRequestConfig } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { useAxios } from '@pig-common/hooks/useAxios'
import { useSetRecoilState } from 'recoil'
import { notificationCommon } from '@pig-common/recoils'

export enum NotificationType {
  DailyQuest = 'DAILY_QUESTS',
}
export type Notifications = {
  dailyQuest: boolean
}
type NotificationHook = {
  notification: Notifications
  callNotification: () => void
  callReadNotification: any
}

const defaultNotifications: Notifications = {
  dailyQuest: false,
}
export const useNotification = ({
  userUID,
}: {
  userUID?: string
}): NotificationHook => {
  const { pigspinApiInstance } = useAxios()
  const setNotification = useSetRecoilState(
    notificationCommon.notificationState,
  )
  const {
    data: notification = defaultNotifications,
    refetch: callNotification,
  } = useQuery<Notifications, any>(
    ['notification'],
    async () => {
      if (isEmpty(userUID)) return Promise.resolve({ dailyQuest: false })
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/user/flag/read?user_code=${userUID}&field_name=${NotificationType.DailyQuest}`,
        method: 'GET',
      }
      const result = await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
      const notifactionResult = { dailyQuest: result.data.flag_status === 0 }
      setNotification(notifactionResult)
      return notifactionResult
    },
    {
      enabled: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      initialData: defaultNotifications,
    },
  )

  const { mutate: callReadNotification } = useMutation({
    mutationFn: async (type: NotificationType, toggle_flag?: 0 | 1) => {
      const flag_status = toggle_flag || 1
      const reqNotification = {
        user_code: userUID,
        type,
        flag_status,
      }
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/user/flag/read?user_code=${userUID}&field_name=${type}&flag_status=${flag_status}`,
        method: 'POST',
        data: reqNotification,
      }
      const resNotification = await fetchWithJSON<any>(
        pigspinApiInstance,
        requestConfig,
      )
      return resNotification
    },
    onSuccess: () => {
      callNotification()
    },
  })
  return {
    notification,
    callNotification,
    callReadNotification,
  }
}
