import { CommonResponse } from '@pig-common/types/Api.type'
import { ProductType } from '@pig-common/types/Product.type'
import {
  CampaignType,
  ICampaignData,
  IDoubleDigitData,
} from '@pig-casino/types/Campaign.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { AxiosRequestConfig } from 'axios'

export interface IParamsProps {
  types?: CampaignType
  productType?: ProductType
}

async function list(
  params: IParamsProps,
): Promise<CommonResponse<ICampaignData[]>> {
  const paramsStr = `?${new URLSearchParams({
    ...params,
    product_type: params?.productType,
  }).toString()}`
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/campaigns${paramsStr}`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

async function getByCode(campaignCode): Promise<CommonResponse<ICampaignData>> {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/campaigns/code/${campaignCode}`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

async function getDoubleDigit(): Promise<CommonResponse<IDoubleDigitData>> {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/loyalty/events/double_digit',
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export const key = {
  list: 'LIST_CAMPAIGN',
  getByCode: 'GET_CAMPAIGN_BY_CODE',
  getDoubleDigit: 'GET_DOUBLE_DIGIT',
}

export { list, getByCode, getDoubleDigit }
