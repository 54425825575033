import isEmpty from 'lodash/isEmpty'
import { atom, selector } from 'recoil'
import { IProfileData } from '@pig-common/types/Profile.type'

export const key = {
  profileState: 'COMMON/PROFILE',
  isProfileVerifiedState: 'COMMON/PROFILE_IS_VERIFIED',
}

export const defaultProfileState: IProfileData | undefined = undefined

export const profileState = atom<IProfileData | undefined>({
  key: key.profileState,
  default: defaultProfileState,
})

export const isProfileVerifiedState = selector<boolean>({
  key: key.isProfileVerifiedState,
  get: ({ get }) => {
    const profile = get(profileState)
    if (isEmpty(profile?.identityNumber)) {
      return false
    }
    return true
  },
})
