import { camelizeKeys } from '@pig-frontend/utils'
import { AxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'
import { authCookieKey } from '@pig-common/types/Authentication.type'
import { api, apiMock } from './api-creators'

const getCookie = (name) => {
  const cookie = new Cookies()
  const cfid = cookie.get(name)
  return cfid
}

api.interceptors.request.use((config: AxiosRequestConfig): any => {
  const authToken = getCookie(authCookieKey.cfid)
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(authToken
        ? {
            authorisation: authToken,
          }
        : {}),
    },
  }
})

api.interceptors.response.use(
  (response): any => {
    const camelizedkeysResponse = camelizeKeys(response?.data)
    return camelizedkeysResponse
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiMock.interceptors.request.use((config: AxiosRequestConfig): any => {
  const authToken = getCookie(authCookieKey.cfid)
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(authToken
        ? {
            authorisation: authToken,
          }
        : {}),
    },
  }
})

apiMock.interceptors.response.use(
  (response): any => {
    const camelizedkeysResponse = camelizeKeys(response?.data)

    return camelizedkeysResponse
  },
  (error) => {
    return Promise.reject(error)
  },
)
