import bn from 'bignumber.js'
import Loading from '@pig-casino/components/loading'
import { LayoutBalanceIcon } from '@pig-casino/constants/images'
import useBalance from '@pig-casino/hooks/useBalance'
import styles from './index.module.scss'

export default function Balance() {
  const { data: balance, isFetching: isLoading } = useBalance({})

  return (
    <div className={styles.container}>
      <LayoutBalanceIcon className={styles.icon} />
      {isLoading ? (
        <Loading type="circle" />
      ) : (
        <span data-testid="component-balance-balance">
          {/* NOTE: Temporary remove bonus; don't forget to check test file */}
          {/* {bn(
            (balance?.balanceAmount || 0) + (balance?.coinAmount || 0),
          ).toFormat(2)} */}
          {bn(balance?.balanceAmount || '0').toFormat(2)}
        </span>
      )}
    </div>
  )
}
