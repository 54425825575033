import cx from 'classnames'
import { Divider as AntdDivider, DividerProps as AntdDividerProps } from 'antd'
import styles from './index.module.scss'

interface IDividerProps extends AntdDividerProps {
  /** if pass only number, it will take effect only vertical */
  margin?:
    | {
        top?: number
        bottom?: number
      }
    | number
}

export function Divider({ className, margin, ...props }: IDividerProps) {
  return (
    <AntdDivider
      {...props}
      className={cx(styles.container, className)}
      {...(typeof margin === 'number'
        ? { style: { margin: `${margin}px 0px ${margin}px 0px` } }
        : {
            style: {
              marginTop: margin?.top ?? 0,
              marginBottom: margin?.bottom ?? 0,
            },
          })}
    />
  )
}
