import { StarIcon, StarGreyIcon } from '@pig-common/constants/images'
import styles from './index.module.scss'

interface IUserStarProps {
  progress?: number
}

export default function UserStar({ progress = 0 }: IUserStarProps) {
  return (
    <div className={styles['user-star']}>
      <StarIcon
        style={{
          width: '100%',
          clipPath: `inset(0 ${100 - progress}% 0 0)`,
        }}
      />
      <StarGreyIcon
        style={{
          width: '100%',
          clipPath: `inset(0 0 0 ${progress}%)`,
        }}
      />
    </div>
  )
}
