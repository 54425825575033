import { ReactNode } from 'react'
import styles from './index.module.scss'

export interface BodyProps {
  children?: ReactNode
}

export default function Body({ children }: BodyProps) {
  return (
    <main id="body" className={styles.container}>
      {children}
    </main>
  )
}
