import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  FlagStatusTypes,
  ICreateReadFlagResponse,
  IReadFlagData,
} from '@pig-common/types/ReadFlag.type'

export interface ICreateReadFlagParams {
  userUID?: string
  flagStatus?: number
  fieldName?: FlagStatusTypes
}

async function create({
  userUID,
  flagStatus,
  fieldName,
}: ICreateReadFlagParams): Promise<CommonResponse<ICreateReadFlagResponse>> {
  return await api.post(
    '/v1/user/flag/read',
    {
      user_code: userUID,
      type: fieldName,
      flag_status: flagStatus,
    },
    {
      params: {
        user_code: userUID,
        field_name: fieldName,
        flag_status: flagStatus,
      },
    },
  )
}

export interface IGetReadFlagParams {
  userUID?: string
  fieldName?: FlagStatusTypes
}

async function get({
  userUID,
  fieldName,
}: IGetReadFlagParams): Promise<CommonResponse<IReadFlagData>> {
  return await api.get('/v1/user/flag/read', {
    params: {
      user_code: userUID,
      field_name: fieldName,
    },
  })
}

export const key = {
  create: 'CREATE_READ_FLAG',
  get: 'GET_READ_FLAG',
}

export { create, get }
