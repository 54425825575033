import { api } from '@pig-common/api/api-creators'
import { API_BO, PRODUCT_NAME } from '@pig-common/constants/env'
import { CommonResponse } from '@pig-common/types/Api.type'
import { ISystemStatusResponse } from '@pig-common/types/SystemStatus.type'
import { ProductType } from '@pig-common/types/Product.type'

const systemStatusProductTypeMapper: Record<string, ProductType> = {
  PIGSPIN: 'PIG_SPIN',
  PIGBET: 'PIG_BET',
}

async function get(): Promise<CommonResponse<ISystemStatusResponse>> {
  return await api.get(
    `/v1/feature-toggle/system-status?product_type=${
      systemStatusProductTypeMapper?.[PRODUCT_NAME || 'PIGSPIN']
    }`,
    {
      baseURL: API_BO,
    },
  )
}

export const key = {
  get: 'SYSTEM_STATUS',
}

export { get }
