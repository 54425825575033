import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

interface IClaimParams {
  customerCode?: string
}

async function claim({
  customerCode,
}: IClaimParams): Promise<CommonResponse<null>> {
  return await api.post(
    '/v1/wallet/casino/commission/claim-commission',
    undefined,
    {
      params: {
        customer_code: customerCode,
      },
    },
  )
}

export const key = {
  claim: 'COMMISSION_CLAIM',
}

export { claim }
