import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IAchievementItemProps,
  IAchievementProps,
} from '@pig-common/types/Achievement.type'
import { DefaultType } from '@pig-common/types/Types.type'

export interface IGetAchievementParams {
  customerCode?: string
}

async function get({
  customerCode,
}: IGetAchievementParams): Promise<CommonResponse<IAchievementProps>> {
  return await api.get(`/v1/loyalty/achievements/${customerCode}`)
}

async function getHighlight<
  SelectReturnType extends IAchievementItemProps &
    DefaultType = IAchievementItemProps[],
>({
  customerCode,
}: IGetAchievementParams): Promise<CommonResponse<SelectReturnType>> {
  return await api.get(`/v1/loyalty/achievements/${customerCode}/highlight`)
}

export interface ISetAchievementSetHighlightParams {
  customerCode?: string
  position?: number
  userInventoryUid?: string
}

async function setHighlight({
  customerCode,
  position,
  userInventoryUid,
}: ISetAchievementSetHighlightParams): Promise<
  CommonResponse<IAchievementItemProps[]>
> {
  return await api.post('/v1/loyalty/set/highlight', {
    customer_code: customerCode,
    position,
    user_inventory_uid: userInventoryUid,
  })
}

export const key = {
  get: 'GET_ACHIEVEMENT',
  getHighlight: 'GET_ACHIEVEMENT_HIGHLIGHT',
  setHighlight: 'SET_ACHIEVEMENT_HIGHLIGHT',
}

export { get, getHighlight, setHighlight }
