import { useEffect, useState } from 'react'
import { useMediaQuery as useReactResponsive } from 'react-responsive'
import { userAgentType } from '@pig-common/types/Device.type'

export type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const breakPointSize: Record<BreakPoint, string> = {
  xs: '320px',
  sm: '375px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
}

export const useMediaQuery = (
  screenSize: BreakPoint,
  userAgent?: userAgentType,
) => {
  const [isClient, setIsClient] = useState<boolean>(false)
  const isMedia = useReactResponsive({ minWidth: breakPointSize[screenSize] })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true)
    }
  }, [])

  return isClient ? isMedia : userAgent === 'desktop'
}
