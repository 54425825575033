import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import useVIPStatus from '@pig-common/hooks/useVipStatus'
import styles from './index.module.scss'

interface IUserExpBarProps {
  className?: string
}

export default function UserExpBar({ className }: IUserExpBarProps) {
  const { data: vipStatus } = useVIPStatus({ enabled: false })

  return (
    <>
      {(!isEmpty(vipStatus?.userVipLevel) || !vipStatus?.userProgress) && (
        <div className={cx(styles.container, className)}>
          <div className={styles.bar}>
            <div className={cx(styles.label, styles.left)}>
              {vipStatus?.userVipLevel}
            </div>
            <div className={cx(styles.label, styles.right)}>
              {vipStatus?.nextLevel?.vipLevel}
            </div>
            <div
              className={styles.progress}
              style={{
                width: `${vipStatus?.userProgress}%`,
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}
