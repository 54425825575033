import { Checkbox as AntdCheckbox, CheckboxProps } from 'antd'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import cx from 'classnames'
import styles from './index.module.scss'

export function Checkbox({ className, ...props }: CheckboxProps) {
  return <AntdCheckbox {...props} className={cx(styles.checkbox, className)} />
}

Checkbox.Group = ({ className, ...props }: CheckboxGroupProps) => {
  return (
    <AntdCheckbox.Group
      {...props}
      className={cx(styles['checkbox-group'], className)}
    />
  )
}
