import { NavigationItem } from '@pig-frontend/uikit'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { pageLink } from '@pig-casino/constants/page-name'
import {
  HomeOutlined,
  NotificationOutlined,
  BellOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { AppSwitcher } from '@pig-casino/components/navigation-bar'

export const navigationBarItems: NavigationItem[] = [
  {
    label: 'หน้าแรก',
    link: pageLinkCommon.lobby,
    icon: <HomeOutlined />,
    showBadge: false,
  },
  {
    label: 'เกมโปรด',
    link: `${pageLink.games.favorite}?favorite=true`,
    icon: <StarOutlined />,
    showBadge: false,
  },
  {
    label: 'คาสิโน',
    icon: <AppSwitcher />,
    showBadge: false,
  },
  {
    label: 'โปรโมชั่น',
    link: pageLinkCommon.promotions,
    icon: <NotificationOutlined />,
    showBadge: false,
  },
  {
    label: 'การแจ้งเตือน',
    link: pageLinkCommon.notifications,
    icon: <BellOutlined />,
    showBadge: false,
  },
]
