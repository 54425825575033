import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'
import { authStateCommon } from '@pig-common/recoils'
import isEmpty from 'lodash/isEmpty'
import { Cookies } from 'react-cookie'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { usePrevious } from '@pig-frontend/uikit'
import jwtDecode from 'jwt-decode'
import useLogout from '@pig-common/hooks/useLogout'
import useVerifyToken from '@pig-common/hooks/useVerifyToken'
import { pageLinkCommon } from '@pig-common/constants/page-name'

export function useAuth() {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { logout } = useLogout()
  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const cookie = new Cookies()
  const prevCfid = usePrevious(cookie.get(COOKIES_KEY.CFID))
  const prevCustomerCode = usePrevious(cookie.get(COOKIES_KEY.CUSTOMER_CODE))
  const {
    data: tokenVerifiedStatus,
    remove: resetTokenVerifyState,
    refetch: verifyToken,
  } = useVerifyToken({
    select: (data) => data?.status,
    enabled: false,
  })

  useEffect(() => {
    const cfid = cookie.get(COOKIES_KEY.CFID)
    const customerCode = cookie.get(COOKIES_KEY.CUSTOMER_CODE)
    if (prevCfid !== cfid || prevCustomerCode !== customerCode) {
      // Check cookie effect manual, Due to hook/useCookie is not re-render
      if (isAuthenticated && (isEmpty(cfid) || isEmpty(customerCode))) {
        logout()
      } else {
        try {
          const decodedJWT: Record<string, string> = jwtDecode(
            cfid?.split(' ')?.[1],
          )
          setAuth({
            cfid,
            customerCode,
            userUID: decodedJWT?.user_uid,
            gameToken: decodedJWT?.game_token,
          })
        } catch (error) {
          console.error('[SYSTEM] JWT is invalid format')
        }
      }
    }
  })

  useEffect(() => {
    // Always verify token when page change like system-status
    if (isAuthenticated && router.pathname !== pageLinkCommon.authenticate) {
      verifyToken()
    }
  }, [isAuthenticated, router.pathname])
  useEffect(() => {
    if (!isAuthenticated) {
      resetTokenVerifyState()
    }
  }, [isAuthenticated])
  useEffect(() => {
    // Force logout if token is invalid
    if (isAuthenticated && tokenVerifiedStatus === 'ERROR') {
      logout()
    }
  }, [isAuthenticated, tokenVerifiedStatus])

  return isAuthenticated
}
