import BigNumber from 'bignumber.js'

// IMRPOVE: make it support minus value

// TODO change default fontsize to use from main variable
const defaultFontSizePx = '24' //px
BigNumber.config({ DECIMAL_PLACES: 5 })

export function PXToREMConverter(fontSizePx = `${defaultFontSizePx}px`) {
  if (!fontSizePx.match(/^[0-9]*px$/gm)) {
    throw Error(
      '[SYSTEM ERROR] error function PXToREMConverter; Please pass pixel value as string with px unit. Ex.24px',
    )
  }
  const pixeNumber = fontSizePx.replace(/px/g, '')

  return `${BigNumber(pixeNumber).dividedBy(defaultFontSizePx).toFormat()}rem`
}
