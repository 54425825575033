import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { balanceApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'

interface IGetBalanceType {
  select?: (
    data: CommonResponse<balanceApi.IBalanceResponse>,
  ) => balanceApi.IBalanceResponse
  enabled?: boolean
  refetchInterval?: number
}

export default function useBalance({
  select,
  enabled,
  refetchInterval,
}: Partial<IGetBalanceType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { userUID } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [balanceApi.key.get, userUID],
    queryFn: () => balanceApi.get({ userUID }),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
    refetchInterval,
  })
}
