import axios from 'axios'
import { API_BASE, NODE_ENV } from '@pig-common/constants/env'

export const api = axios.create({
  baseURL: API_BASE,
  headers: { 'Accept-Encoding': '' },
})

export const apiMock = axios.create({
  baseURL: NODE_ENV === 'development' ? 'http://localhost:3001' : API_BASE,
})
