import {
  MAXIMUM_DISPLAYABLE_BALANCE,
  MINIMUM_DISPLAYABLE_BALANCE,
} from '@pig-common/models/runtime-constant'
import { numberWithCommas, numberWithoutCommas } from './number'

export function getInboundBalance(rawBalance = '0.00') {
  const realNumber = numberWithoutCommas(rawBalance)
  if (realNumber > MAXIMUM_DISPLAYABLE_BALANCE) {
    return numberWithCommas(MAXIMUM_DISPLAYABLE_BALANCE)
  }
  if (realNumber < MINIMUM_DISPLAYABLE_BALANCE) return '0.00'
  return numberWithCommas(realNumber, 2)
}

export function getInboundTotalBalance(rawBalance = '0.00', rawBonus = '0.00') {
  const realNumber =
    numberWithoutCommas(rawBalance) + numberWithoutCommas(rawBonus)
  if (realNumber > MAXIMUM_DISPLAYABLE_BALANCE) {
    return numberWithCommas(MAXIMUM_DISPLAYABLE_BALANCE)
  }
  if (realNumber < MINIMUM_DISPLAYABLE_BALANCE) return '0.00'
  return numberWithCommas(realNumber, 2)
}
