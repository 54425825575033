import cx from 'classnames'
import styles from './index.module.scss'

export type LoadingProps = {
  className?: string
  type?: 'circle' | 'line'
}

const Loading = ({ className, type = 'line' }: LoadingProps) => {
  if (type === 'circle') {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}>
          <div className={styles.body} />
        </div>
      </div>
    )
  }
  return <div className={cx(styles['line-container'], className)} />
}

export default Loading
