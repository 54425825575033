import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IGuildAvatarImage,
  IGuildBannerImage,
  IGuildConfigResponse,
  IGuildDataResponse,
  IGuildVIPMember,
} from '@pig-common/types/Guild.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { AxiosRequestConfig } from 'axios'

export interface IGetGuildParams {
  customerCode?: string
}

async function get({
  customerCode,
}: IGetGuildParams): Promise<CommonResponse<IGuildDataResponse>> {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/loyalty/guild${
      customerCode
        ? `?${new URLSearchParams({ customer_code: customerCode }).toString()}`
        : ''
    }`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

async function getConfig(): Promise<CommonResponse<IGuildConfigResponse>> {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/loyalty/guild/config/tickets/',
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

async function listIcon(): Promise<CommonResponse<IGuildAvatarImage[]>> {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/loyalty/guild/icons',
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

async function listBanner(): Promise<CommonResponse<IGuildBannerImage[]>> {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/loyalty/guild/banners',
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export interface IGetGuildDetailParams {
  customerCode?: string
  guildId?: string
}

async function getDetail({
  guildId,
  customerCode,
}: IGetGuildDetailParams): Promise<
  CommonResponse<Partial<IGuildDataResponse['myGuild']>>
> {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/loyalty/guild/detail/${guildId}${
      customerCode
        ? `?${new URLSearchParams({ customer_code: customerCode }).toString()}`
        : ''
    }`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export interface ICreateGuildParams {
  bannerUid: string
  description: string
  guildName: string
  iconUid: string
  customerCode?: string
}

export interface ICreateGuildDataRespnse {
  detail?: string
  message?: string
}

async function create({
  bannerUid,
  description,
  guildName,
  iconUid,
  customerCode,
}: ICreateGuildParams): Promise<CommonResponse<ICreateGuildDataRespnse>> {
  return await api.post(
    `/v1/loyalty/guild/create?customer_code=${customerCode}`,
    {
      banner_uid: bannerUid,
      description,
      guild_name: guildName,
      icon_uid: iconUid,
    },
  )
}

export interface IEditGuildParams {
  bannerUid: string
  description: string
  guildName: string
  iconUid: string
  guildUid: string
  customerCode?: string
}

export interface IEditGuildDataRespnse {
  detail?: string
  message?: string
}

async function edit({
  bannerUid,
  description,
  guildName,
  guildUid,
  iconUid,
  customerCode,
}: IEditGuildParams): Promise<CommonResponse<IEditGuildDataRespnse>> {
  return await api.post(
    `/v1/loyalty/guild/update?customer_code=${customerCode}`,
    {
      banner_uid: bannerUid,
      description,
      guild_name: guildName,
      icon_uid: iconUid,
      guild_uid: guildUid,
    },
  )
}

export interface ILeaveGuildParams {
  customerCode?: string
}

export interface ILeaveGuildDataResponse {
  message?: string
  detail?: string
}

async function remove({
  customerCode,
}: ILeaveGuildParams): Promise<CommonResponse<ILeaveGuildDataResponse>> {
  return await api.post(`/v1/loyalty/guild/leave?customer_code=${customerCode}`)
}

export interface IApplyGuildParams {
  customerCode?: string
  guildUid: string
  type: 'apply' | 'cancel'
}

export interface IApplyGuildDataResponse {
  message?: string
  detail?: string
}

async function request({
  customerCode,
  guildUid,
  type,
}: IApplyGuildParams): Promise<CommonResponse<IApplyGuildDataResponse>> {
  return await api.post(
    `/v1/loyalty/guild/${
      type !== 'apply' ? 'cancel_request_apply' : 'request_apply'
    }?customer_code=${customerCode}`,
    {
      guild_uid: guildUid,
    },
  )
}

export interface IReviewMemberRequestGuildParams {
  customerCode?: string
  guildUid: string
  requestUid: string
  type: 'accept' | 'decline'
}

export interface IReviewMemberRequestGuildDataResponse {
  message?: string
}

async function reviewMemberRequest({
  customerCode,
  guildUid,
  requestUid,
  type,
}: IReviewMemberRequestGuildParams): Promise<
  CommonResponse<IReviewMemberRequestGuildDataResponse>
> {
  return await api.post(
    `/v1/loyalty/guild/${
      type !== 'accept' ? 'decline_member' : 'accept_member'
    }`,
    {
      customer_code: customerCode,
      guild_uid: guildUid,
      request_uid: requestUid,
    },
  )
}

export interface IExpelMemberGuildParams {
  customerCode?: string
  guildUid: string
  member: string
}

export interface IExpelMemberGuildDataResponse {
  message?: string
}

async function expel({
  customerCode,
  guildUid,
  member,
}: IExpelMemberGuildParams): Promise<
  CommonResponse<IExpelMemberGuildDataResponse>
> {
  return await api.post('/v1/loyalty/guild/expel', {
    customer_code: customerCode,
    guild_uid: guildUid,
    member,
  })
}

export interface IAssignRoleGuildParams {
  customerCode?: string
  guildUid: string
  member: string
  type: 'assign' | 'unassign'
}

export interface IAssignRoleGuildDataResponse {
  message?: string
}

async function assignRole({
  customerCode,
  guildUid,
  member,
  type,
}: IAssignRoleGuildParams): Promise<
  CommonResponse<IAssignRoleGuildDataResponse>
> {
  return await api.post(
    `/v1/loyalty/guild/${type === 'assign' ? 'assign_role' : 'unassign_role'}`,
    {
      customer_code: customerCode,
      guild_uid: guildUid,
      member,
    },
  )
}

export interface IGetVIPMemberParams {
  /** customer_code of guild member */
  memberCustomerCodes?: (string | undefined)[]
}

async function getVIPMember({
  memberCustomerCodes,
}: IGetVIPMemberParams): Promise<CommonResponse<IGuildVIPMember[]>> {
  return await api.post('/v1/loyalty/vip/members', {
    customer_code: memberCustomerCodes,
  })
}

export const key = {
  get: 'GET_GUILD',
  getConfig: 'GET_GUILD_CONFIG',
  listIcon: 'LIST_ICON_GUILD',
  listBanner: 'LIST_BANNER_GUILD',
  getDetail: 'GET_DETAIL_GUILD',
  create: 'CREATE_GUILD',
  edit: 'EDIT_GUILD',
  remove: 'REMOVE_GUILD',
  request: 'REQUEST_GUILD',
  reviewMemberRequest: 'REVIEW_MEMBER_REQUEST_GUILD',
  expel: 'EXPEL_GUILD',
  assignRole: 'ASSIGN_ROLE_GUILD',
  getVIPMember: 'GET_VIP_MEMBER',
}

export {
  get,
  getConfig,
  listIcon,
  listBanner,
  getDetail,
  create,
  edit,
  remove,
  request,
  reviewMemberRequest,
  expel,
  assignRole,
  getVIPMember,
}
