import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { GameItem } from '@pig-common/services/games'

const { persistAtom } = recoilPersist()

export const key = {
  gameState: 'COMMON/GAME',
}

export type GameState = {
  data: GameItem[]
}

export type GameMetaState = {
  isLaoding: boolean
  isSuccess: boolean
}

export const initialValue: GameItem[] = []

export const gameState = atom<GameItem[]>({
  key: key.gameState,
  default: initialValue,
  effects_UNSTABLE: [persistAtom],
})
