import { AxiosRequestConfig } from 'axios'
import isEmpty from 'lodash/isEmpty'
import { useQuery } from '@tanstack/react-query'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { authStateCommon } from '../recoils'
import { useAxios } from './useAxios'
import { vipStateCommon } from '@pig-common/recoils'
import mapVipBenefits from '@pig-common/mappers/mapVipBenefits'

export enum VIPTiers {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  DIAMOND = 'diamond',
}
export type VipProfileData = {
  star: number
  progress?: number
  vipTier: VIPTiers
  vipLevel: string
  nextLevel?: {
    vipLevel: string
    turnover: number
  }
}
export type VipItem = {
  vipLevel: string
  vipTier: VIPTiers
  vipStar: number
  customerCode?: string
}
export type VipRewardItem = {
  icon: string
  level: string
  tier: string
  detail: string[]
}
export type VipMasterData = {
  tier_icon: string
  tier: string
  levels: {
    vip_master_uid: string
    level: string
    acc_turnover: number
    star: number
    bonus_button: string
  }
}

type VipData = {
  next_vip: VipRewardItem
  current_vip: VipRewardItem
  vip_master: VipMasterData[]
}
export const emptyVip: VipProfileData = {
  star: 0,
  progress: 0,
  vipTier: VIPTiers.BRONZE,
  vipLevel: 'VIP0',
}

export type VipMaster = {
  tier: string
  icon: string
  acc_turnover: number
  tier_icon: string
  level: number
}

export type VipReward = {
  detail: string
}

export type VipList = {
  vip_master: VipMaster
  vip_reward: VipReward[]
}

export type VipHook = {
  myVipData: VipProfileData
  vipTierList: VipList[]
  callMyVip: () => void
  callVipPageData: () => Promise<any | null>
  callClaimReward: (rewardUid: string) => Promise<any | null>
  callPlayerVipInfo: (customerCode: string[]) => Promise<VipItem[]>
  callVipTierList: () => void
}
export const mapTier = (tier: string): VIPTiers => {
  if (isEmpty(tier)) return VIPTiers.BRONZE
  const tierString = tier.toLowerCase()
  if (tierString.includes('diamond')) return VIPTiers.DIAMOND
  if (tierString.includes('gold')) return VIPTiers.GOLD
  if (tierString.includes('silver')) return VIPTiers.SILVER
  return VIPTiers.BRONZE
}
const mapVipInfo = (vipData: any): VipProfileData => {
  const { user_progress, user_star, user_tier, user_vip_level, next_level } =
    vipData
  const nextLevel =
    next_level?.vip_level && next_level?.turnover >= 0
      ? {
          vipLevel: next_level.vip_level,
          turnover: next_level.turnover,
        }
      : undefined
  const result: VipProfileData = {
    star: user_star,
    progress: user_progress,
    vipTier: mapTier(user_tier),
    vipLevel: user_vip_level,
    nextLevel,
  }
  return result
}

export const useVip = (): VipHook => {
  const { pigspinApiInstance } = useAxios()
  const setMyVipState = useSetRecoilState(vipStateCommon.vipState)
  const auth = useRecoilValue(authStateCommon.authState)
  const { data: myVipData, refetch: callMyVip } = useQuery<VipProfileData, any>(
    ['VipData'],
    async () => {
      if (isEmpty(auth.customerCode)) return emptyVip
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/${auth.customerCode}/vip/status`,
        method: 'GET',
      }
      const response = await fetchWithJSON<any>(
        pigspinApiInstance,
        requestConfig,
      )
      if (!isEmpty(response?.data)) {
        const result: VipProfileData = mapVipInfo(response.data)
        setMyVipState({ vip: result })
        return result
      }
      return emptyVip
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const callVipPageData = async (): Promise<VipData | null> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${auth.customerCode}/vip/summary`,
      method: 'GET',
    }
    const response = await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
    return response?.data || null
  }
  const callClaimReward = async (rewardUid: string): Promise<any | null> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${auth.customerCode}/vip/redeem?vip_master_uid=${rewardUid}`,
      method: 'POST',
    }
    const response = await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
    if (!!response?.data && !isEmpty(response?.data)) {
      return response?.data
    }
    return null
  }
  const callPlayerVipInfo = async (
    customerCode: string[],
  ): Promise<VipItem[]> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/loyalty/vip/members',
      method: 'POST',
    }
    const payload = {
      customer_code: customerCode,
    }
    const response = await fetchWithJSON<any>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
    const vipData = response.data || []
    const result: VipItem[] = vipData.map((data: any) => {
      try {
        return {
          customerCode: data.customer_code,
          vipLevel: data.level,
          vipTier: mapTier(data.tier),
          vipStar: data.star,
        }
      } catch {
        return undefined
      }
    })
    return result
  }
  const { data: vipTierList = [], refetch: callVipTierList } = useQuery<
    VipList[]
  >(
    ['VipTierList'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/vip/',
        method: 'GET',
      }
      const response = await fetchWithJSON<any>(
        pigspinApiInstance,
        requestConfig,
      )
      const result: VipList[] = mapVipBenefits(response.data)
      return result || []
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  return {
    myVipData: myVipData || emptyVip,
    vipTierList,
    callMyVip,
    callVipPageData,
    callClaimReward,
    callPlayerVipInfo,
    callVipTierList,
  }
}
