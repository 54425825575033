import { atom } from 'recoil'

import { KEYS } from '../keys'

export enum FAQ_TAB {
  GENERAL = 'GENERAL',
  QUEST = 'QUEST',
  INVITE_FRIEND = 'INVITE_FRIEND',
}

type FaqState = {
  selectedTab: FAQ_TAB
}

export const initialValue: Partial<FaqState> = {
  selectedTab: FAQ_TAB.GENERAL,
}

export const faqState = atom<Partial<FaqState>>({
  key: KEYS.FAQ,
  default: initialValue,
})
