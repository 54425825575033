import { atom } from 'recoil'
import { RecentGameItem } from '@pig-common/hooks/useRecentGame'

export interface RecentGameMeta {
  isLaoding: boolean
  isSuccess: boolean
  data: RecentGameItem[]
}

const key = {
  recentGameState: 'COMMON/RECENT_GAME',
}

export const defaultRecentGameState = {
  isLaoding: false,
  isSuccess: false,
  data: [],
}

export const recentGameState = atom<RecentGameMeta>({
  key: key.recentGameState,
  default: defaultRecentGameState,
})
