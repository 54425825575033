import { NextRouter } from 'next/router'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'

/**
 *
 * NOTE: auto clear undefined value by removing it from url
 * And redirect using shallow make page not reloading
 *
 */
export function updateUrlQuery(router: NextRouter, values: object) {
  const queries = router.query
  const newQueries = omitBy({ ...queries, ...values }, isEmpty)
  router.push({ query: newQueries }, undefined, {
    shallow: true,
  })
  return newQueries
}
