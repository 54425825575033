import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

export interface IAuthenWithSecretKeyRequestParams {
  keygen: string
}

export interface IAuthenWithSecretKeyResponse {
  token?: string
}

export interface IGeneratedSecretKeyRequest {
  token: string
}

export interface IGetGeneratedSecretKeyResponse {
  keygen?: string
}

async function getToken({
  token,
}: IGeneratedSecretKeyRequest): Promise<
  CommonResponse<IGetGeneratedSecretKeyResponse>
> {
  return await api({
    method: 'post',
    url: '/v1/auth/generate-keygen',
    data: {
      token,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

async function authen({
  keygen,
}: IAuthenWithSecretKeyRequestParams): Promise<
  CommonResponse<IAuthenWithSecretKeyResponse>
> {
  return await api.post(
    '/v1/auth/login-with-keygen',
    { keygen },
    { headers: { 'Content-Type': 'multipart/form-data' } },
  )
}

export const key = {
  authen: 'AUTHEN_WITH_SECRET_KEY',
  getToken: 'GENERATE_SECRET_KEY',
}

export { authen, getToken }
