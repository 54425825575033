import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { useRouter } from 'next/router'
import { PIGSPIN_URL } from '@pig-casino/constants/env'
import useGenerateSecretKey from '@pig-casino/hooks/useGenerateSecretKey'

/*
 * @NOTE We should move this hook to pig-common one day
 */
export default function useAppSwitcher() {
  const router = useRouter()
  const [cookies] = useCookies([COOKIES_KEY.CUSTOMER_CODE])
  const {
    data: generatedSecretKey,
    isSuccess: isGeneratedSecretSuccess,
    isError: isGeneratedSecretError,
    refetch: getGeneratedSecretKey,
  } = useGenerateSecretKey({ enabled: false })

  const handleSwitch = () => {
    getGeneratedSecretKey()
  }

  useEffect(() => {
    if (isGeneratedSecretSuccess && generatedSecretKey) {
      router.push(
        `${PIGSPIN_URL}/authenticate?keygen=${generatedSecretKey?.keygen}&customerCode=${cookies?.customer_code}`,
      )
    } else if (isGeneratedSecretError) {
      router.push(`${PIGSPIN_URL}/authenticate`)
      console.error('[SYSTEM] Generate secret key API is error')
    }
  }, [isGeneratedSecretSuccess, generatedSecretKey, isGeneratedSecretError])

  return [{}, { handleSwitch }]
}
