import { getProfile } from '@pig-common/services/profile/profile'
import { IProfileData } from '@pig-common/types/Profile.type'

export interface IGetProfileParams {
  queryBy?:
    | 'ID_CARD'
    | 'USER_CODE'
    | 'PHONE_NUMBER'
    | 'BANK_ACCOUNT'
    | 'TRUE_WALLET'
  idCard?: string
  userCode?: string
  phoneNumber?: string
  phoneNumberPattern?: string
  idCardPattern?: string
  backIdCardPattern?: string
  bankAccountNumber10DigitsPattern?: string
  bankAccountNumber12DigitsPattern?: string
}

export interface IGetProfileHeaders {
  authorisation?: string
}

async function get(
  params: IGetProfileParams,
  headers?: IGetProfileHeaders,
): Promise<IProfileData | null> {
  const isAuthen = !!(params.userCode && headers?.authorisation)
  if (isAuthen) {
    return await getProfile({
      query_by: 'USER_CODE',
      user_code: params.userCode,
      phone_number_pattern: '********00',
      id_card_pattern: '*********0000',
      back_id_card_pattern: '**********00',
      bank_account_number_10_digits_pattern: '******0000',
      bank_account_number_12_digits_pattern: '********0000',
      token: headers?.authorisation || '',
    })
  } else {
    return null
  }
}

export const key = {
  get: 'GET_PROFILE',
}

export { get }
