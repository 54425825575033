import { ReactNode } from 'react'
import cx from 'classnames'
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { notification as antdNotification } from 'antd'
import styles from './index.module.scss'

interface INotificationProps {
  key?: string
  duration?: number
  message?: string | ReactNode
  icon?: ReactNode
  className?: string
  placement?:
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
}

export type NotificationTypes =
  | 'error'
  | 'info'
  | 'warning'
  | 'success'
  | 'destroy'

/**
 *
 * @WARNING Antd notification is not able to put component in body (without portal) yet
 * So CI will not work here
 *
 * @IMPROVEMENT If antd notification can be moved to body
 * please change color variable to using CI variable
 *
 */

const info = ({
  message = '',
  duration,
  icon,
  className,
  placement = 'top',
  ...props
}: INotificationProps) =>
  antdNotification.info({
    message,
    className: cx(styles.container, styles['info-container'], className),
    duration,
    icon: icon || (
      <InfoCircleOutlined className={cx(styles.icon, styles.info)} />
    ),
    placement,
    ...props,
  })

const success = ({
  message = '',
  duration,
  icon,
  className,
  placement = 'top',
  ...props
}: INotificationProps) => {
  antdNotification.success({
    message,
    className: cx(styles.container, className),
    duration,
    icon: icon || (
      <CheckCircleOutlined className={cx(styles.icon, styles.success)} />
    ),
    placement,
    ...props,
  })
}

const error = ({
  message = '',
  duration,
  icon,
  className,
  placement = 'top',
  ...props
}: INotificationProps) => {
  antdNotification.error({
    message,
    className: cx(styles.container, className),
    duration,
    icon: icon || (
      <InfoCircleOutlined className={cx(styles.icon, styles.error)} />
    ),
    placement,
    ...props,
  })
}

const warning = ({
  message = '',
  duration,
  icon,
  className,
  placement = 'top',
  ...props
}: INotificationProps) => {
  antdNotification.warning({
    message,
    className: cx(styles.container, styles['warning-container'], className),
    duration,
    icon: icon || (
      <InfoCircleOutlined className={cx(styles.icon, styles.warning)} />
    ),
    placement,
    ...props,
  })
}

const destroy = ({ key }: INotificationProps) => {
  antdNotification.destroy(key)
}

export const notification: Record<
  NotificationTypes,
  (props: INotificationProps) => void
> = {
  info,
  success,
  error,
  warning,
  destroy,
}
