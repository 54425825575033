import { useCookies as externalUseCookies } from 'react-cookie'
import { addDays } from 'date-fns'

import { BankCodeName } from '@pig-common/models/shared-types'

enum COOKIES_KEY {
  WELCOME = 'welcome',
  LAST_FETCH_BALANCE_AT = 'last_fetch_balance_at',
  USER = 'user',
  SESSION = 'session',
  REGISTER = 'register',
  OTP = 'otp',
  RESET_PIN = 'reset_pin',
  LANDING_GET_OTP_TIMESTAMP = 'landing_get_otp_timestamp',
  DEPOSIT_MODE = 'deposit_mode',
  SYSTEM_STATUS = 'system_status',
  CFID = 'cfid',
  UID = 'uid',
  REFERRER = 'referrer',
  UTM = 'utm',

  EXISTING_PHONE = 'existing_phone',
  EXISTING_PIN = 'existing_pin',
  // TODO : LINE_STATE & LINE_DATA might not use anymore.
  LINE_STATE = 'line_state',
  LINE_DATA = 'line_data',
  AUTH = 'auth',
  CUSTOMER_CODE = 'customer_code',
  USER_CODE = 'user_code',
  BIG_WIN_TOAST = 'big_win_toast',
  TUTORIAL_BIG_WIN = 'tutotial_big_win',
  TUTORIAL_BONUS_TOGGLE = 'tutorial_bonus_toggle',
  CHAT_TIMETOKEN_LOG = 'chat_timetoken_log',
  DAILY_SPIN = 'daily_free_spin',
  DAILY_LOGIN = 'daily_login',
}

export type RegisterCookieType = {
  cid?: string
  laserNumber?: string
  firstName?: string
  lastName?: string
  birthday?: string
  bankName?: string
  bankCode?: BankCodeName
  bankLogo?: string
  bankAccountName?: string
  bankAccountNumber?: string
  bankID?: BankCodeName
  token2?: string
}

export type OtpCookieType = {
  phoneNumber?: string
  otpCode?: string
  otpToken?: string
  otpKey?: string
}

export type ReferrerCookieType = {
  referrer?: string
  isFromUrl?: boolean
}

export type UtmCookiesType = {
  firstVisitUrl: string
  utmId?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  utmContent?: string
}

export type WelcomePageCookieType = {
  customerName?: string
  customerCode?: string
}

export type SystemStatusCookies = {
  isDismissed?: boolean
  message?: string
  nextNotifyCycle?: Date
}

export interface CookieSetOptions {
  path?: string
  expires?: Date
  maxAge?: number
  domain?: string
  secure?: boolean
  httpOnly?: boolean
  sameSite?: boolean | 'none' | 'lax' | 'strict'
  encode?: (value: string) => string
}
export type Cookies<C = unknown> = { [key in COOKIES_KEY]?: C }
export type SetCookies<C = unknown> = (
  name: string,
  value: C,
  options?: CookieSetOptions,
) => void
export type RemoveCookies = (name: string, options?: CookieSetOptions) => void

export const useCookies = <T = unknown>(
  dependencies?: string[],
): [Cookies<T>, SetCookies<T>, RemoveCookies] => {
  // NOTE : pre-execution

  const [cookies, setCookies, removeCookies] = externalUseCookies(dependencies)

  // NOTE : post-execution
  const setCookiesHandle = (
    name: string,
    value: any,
    options?: CookieSetOptions,
  ) => {
    const nextThreeDays = addDays(new Date(), 3)
    const defaultOption: CookieSetOptions = {
      expires: nextThreeDays,
      path: '/',
    }
    setCookies(name, value, { ...defaultOption, ...options })
  }

  const removeCookiesHandle = (name: string, options?: CookieSetOptions) => {
    const defaultOption: CookieSetOptions = { path: '/' }
    removeCookies(name, options || defaultOption)
  }

  return [cookies, setCookiesHandle, removeCookiesHandle]
}

export { COOKIES_KEY }
