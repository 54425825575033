import { atom } from 'recoil'
import { KEYS } from '../keys'
import { GameItem } from '@pig-common/services/games'

export type BigWinChampionDetail = {
  game_code?: string
  game_ref_type?: string
  avatar?: string
  betAmount: string
  gameCode?: string
  gameIconUrl: string
  imageUrl: string
  subTitle: string
  title: string
  gameRefType: string
  transactionDate: string
  uid: string
  displayName?: string
  userPhone: string
  game?: GameItem | null
}

export interface BigWinState {
  list: BigWinChampionDetail[]
}

export const initialValue: BigWinState = {
  list: [],
}

export const bigWinState = atom<BigWinState>({
  key: KEYS.BIGWIN,
  default: initialValue,
})
