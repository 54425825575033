import { ThemeConfig } from 'antd/es/config-provider/context'
import { sassVarToNumber } from '@pig-frontend/utils'
import styles from './index.module.scss'

export type ThemeTypes = 'dark' | 'light'

const defaultTheme: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: styles.colorPrimary1,
      colorPrimaryActive: styles.colorPrimary1,
      colorPrimaryBg: styles.colorPrimary1,
      colorPrimaryBgHover: styles.colorPrimary1,
      colorBorder: 'none',
      controlHeight: 46,
      paddingContentHorizontal: 24,
      borderRadius: 32,
      fontSize: 20,
      fontWeight: 700,
    },
    Layout: {
      headerBg: styles.colorWhite,
      bodyBg: styles.colorWhite,
    },
    Steps: {
      colorTextLabel: styles.colorWhite,
    },
    Dropdown: {
      fontSize: 20,
    },
    Divider: {
      marginLG: 0,
    },
    Radio: {
      colorPrimary: styles.colorPrimary1,
      fontSizeLG: 20,
    },
    Checkbox: {
      borderRadiusSM: 1,
    },
    Tabs: {
      colorPrimary: styles.colorPrimary1,
    },
    Badge: {
      colorError: styles.colorRed200,
      fontSizeSM: 12,
    },
    Progress: {
      colorInfo: styles.colorPrimary1,
      colorFillSecondary: '#F0EAFF',
    },
    Modal: {
      borderRadiusLG: 4,
      colorPrimary: 'green',
    },
    Input: {
      colorError: styles.colorRed200,
      colorErrorHover: styles.colorRed200,
      colorErrorOutline: 'transparent',
      controlOutline: 'transparent',
    },
    Form: {
      colorError: styles.colorRed200,
    },
    Spin: {
      colorPrimary: styles.colorPrimary1,
    },
  },
  token: {
    colorPrimaryHover: styles.colorPrimary1,
    colorPrimary: styles.colorPrimary1,
    fontFamily: styles.fontFamily,
    fontSize: sassVarToNumber(styles.fontSizeBase),
    colorText: styles.colorGreyscale600,
    controlItemBgActive: styles.colorViolet100,
    controlItemBgActiveHover: styles.colorViolet100,
    controlItemBgHover: styles.colorViolet100,
  },
}

export { defaultTheme }
