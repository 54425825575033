import cx from 'classnames'
import styles from './index.module.scss'

interface HighlightProps {
  type?: 'primary' | 'danger' | 'black'
  children?: string
  className?: string
}

export function Highlight({
  type = 'primary',
  children,
  className,
  ...props
}: HighlightProps) {
  return (
    <strong
      className={cx(styles.highlight, styles[type], className)}
      {...props}
    >
      {children}
    </strong>
  )
}
