import { VipList, mapTier } from '@pig-common/hooks/useVip'

const mapVipBenefits = (data: VipList[]): VipList[] => {
  return (
    data.map((item) => {
      return {
        vip_master: {
          tier: mapTier(item.vip_master?.tier),
          level: item.vip_master?.level,
          icon: '',
          acc_turnover: 0,
          tier_icon: '',
        },
        vip_reward: item.vip_reward,
      }
    }) || []
  )
}
export default mapVipBenefits
