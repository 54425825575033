import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IPromotionsResultData,
  IPromotionsData,
} from '@pig-casino/types/Promotions.type'
import { ProductType } from '@pig-common/types/Product.type'

export interface IListParamsProps {
  productType?: ProductType
}

async function list(
  params: IListParamsProps,
): Promise<CommonResponse<IPromotionsData>> {
  return await api.get('/v1/promotions/', {
    params: {
      ...params,
      product_type: params?.productType,
    },
  })
}

async function getByCode(
  promotionCode,
): Promise<CommonResponse<IPromotionsResultData>> {
  return await api.get(`/v1/promotions/code/${promotionCode}`)
}

export const key = {
  list: 'LIST_PROMOTIONS',
  getByCode: 'GET_PROMORIONS_BY_CODE',
}

export { list, getByCode }
