import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGuildDataResponse } from '@pig-common/types/Guild.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface IGetGuildType<SelectReturnType = IGuildDataResponse> {
  params?: guildApi.IGetGuildParams
  select?: (data: CommonResponse<IGuildDataResponse>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useGuild<SelectReturnType = IGuildDataResponse>({
  params,
  select,
  enabled,
}: IGetGuildType<SelectReturnType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [guildApi.key.get],
    queryFn: async () => {
      const result = await guildApi.get({
        customerCode: params?.customerCode || customerCode || '',
      })
      return camelizeKeys(result) as CommonResponse<IGuildDataResponse>
    },
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data),
  })
}
