import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IGetTotalTicketResponse,
  IRedeemRewardResponse,
} from '@pig-common/types/Ticket.type'

export interface IGetTicketParams {
  customerCode?: string
}

export interface IRedeemRewardParams {
  customerCode?: string
  userUID?: string
  rewardUID?: string
}

async function getTotal({
  customerCode,
}: IGetTicketParams): Promise<CommonResponse<IGetTotalTicketResponse>> {
  return await api.get(`v1/loyalty/${customerCode}/totalticket`)
}

async function redeemReward({
  customerCode,
  userUID,
  rewardUID,
}: IRedeemRewardParams): Promise<CommonResponse<IRedeemRewardResponse>> {
  return await api.post(
    `v1/loyalty/${customerCode}/${userUID}/shop/redeem/${rewardUID}`,
  )
}

export const key = {
  getTotal: 'GET_TOTAL_TICKET',
  redeemReward: 'BIGWIN_REDEEM_REWARD',
}

export { getTotal, redeemReward }
