/* eslint-disable  */
// @ts-nocheck
// Coppied from next official document
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // handle error catched
    location.reload()
    console.error('[SYSTEM ERROR]: ', { error, errorInfo })
  }
  render() {
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return <div />
    }

    return this.props?.children
  }
}

export default ErrorBoundary
