import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IDailySpinCheckFreeResponseData,
  IDailySpinHistoryRankResponseData,
  IDailySpinHistoryResponseData,
} from '@pig-common/types/DailySpin.type'

async function spinWithTicket(): Promise<
  CommonResponse<IDailySpinHistoryResponseData>
> {
  return await api.post('/v1/loyalty/spin/ticket')
}

async function getHistory(): Promise<
  CommonResponse<IDailySpinHistoryResponseData[]>
> {
  return await api.get('/v1/loyalty/spin/history')
}

async function spinWithFree(): Promise<
  CommonResponse<IDailySpinHistoryResponseData>
> {
  return await api.post('/v1/loyalty/spin/free')
}

async function getFreeSpinStatus(): Promise<
  CommonResponse<IDailySpinCheckFreeResponseData>
> {
  return await api.get('/v1/loyalty/spin/free')
}

async function createCheckIn(): Promise<CommonResponse<unknown>> {
  return await api.post('/v1/loyalty/check-in')
}

async function getHistoryRank(): Promise<
  CommonResponse<IDailySpinHistoryRankResponseData>
> {
  return await api.post('/v1/loyalty/spin/history-ranking', {
    date_type: 'month',
    order_by: 'desc',
    pagination: { page: 1, size: 5 },
  })
}

export const key = {
  spinWithTicket: 'SPIN_WITH_TICKET',
  getHistory: 'GET_SPIN_HISTORY',
  spinWithFree: 'SPIN_WITH_FREE',
  getFreeSpinStatus: 'GET_FREE_SPIN_STATUS',
  createCheckIn: 'CREATE_CHECK_IN',
  getHistoryRank: 'GET_SPIN_HISTORY_RANK',
}

export {
  spinWithTicket,
  getHistory,
  spinWithFree,
  getFreeSpinStatus,
  createCheckIn,
  getHistoryRank,
}
