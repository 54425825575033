import { atom, selector } from 'recoil'
import {
  getInboundBalance,
  getInboundTotalBalance,
} from '@pig-common/utils/wallet'

export interface IWalletState {
  total: string
  balance: string
  coin: string
}

export const key = {
  walletState: 'COMMON/WALLET',
  walletSelectorState: 'COMMON/WALLET_SELECTOR',
}

export const defaultWalletState: IWalletState = {
  total: '',
  balance: '',
  coin: '',
}

export const walletState = atom<IWalletState | undefined>({
  key: key.walletState,
  default: defaultWalletState,
})

export const walletSelectorState = selector({
  key: key.walletSelectorState,
  set: ({ set }, newWallet: any) => {
    const balance = getInboundBalance(newWallet?.balance)
    const coin = getInboundBalance(newWallet?.coin)
    const total = getInboundTotalBalance(newWallet?.balance, newWallet?.coin)
    const validateWallet = {
      total,
      balance,
      coin,
    }
    set(walletState, validateWallet)
  },
  get: ({ get }) => {
    const wallet = get(walletState)
    const walletTotal = wallet?.total
    const walletBalance = wallet?.balance
    const walletCoin = wallet?.coin

    return {
      walletTotal,
      walletBalance,
      walletCoin,
    }
  },
})
