import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

export interface IRequestParams {
  userUID: string
}

// TODO: replace unknow types
interface IWalletResultResponse {
  cashWalletTransaction?: unknown
  convertLimitAmount?: number
  convertedToCashAmount?: number
  createdAt?: string
  currentBalance?: number
  currentTurnover?: number
  expireAt?: string
  initialBalance?: number
  isActive?: boolean
  isConvertedToCash?: boolean
  name?: string
  order?: string
  promotionUid?: string | null
  status?: 'active'
  tier?: unknown | null
  tierRatio?: unknown | null
  totalTurnover?: number
  turnOverRatio?: number
  uid?: string
  updatedAt?: string
}

export interface IWalletResponse {
  total?: number
  results?: IWalletResultResponse[]
}

async function get({
  userUID,
}: IRequestParams): Promise<CommonResponse<IWalletResponse>> {
  return await api.get(`/v1/wallet/${userUID}/coin-wallet`)
}

export const key = {
  get: 'COIN_WALLET',
}

export { get }
