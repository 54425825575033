import placeholderImage from '@pig-frontend/uikit/assets/images/placeholder.png'
import modalCongratImage from '@pig-frontend/uikit/assets/images/modal-congrat.png'

// SVG
import { ReactComponent as HeadPhoneIcon } from '@pig-frontend/uikit/assets/images/floating-button-headphone.svg'
import { ReactComponent as VipIcon } from '@pig-frontend/uikit/assets/images/vip-logo.svg'

// Profile avatar
import pixelImage from '@pig-frontend/uikit/assets/images/profile-avatar/pixel.png'
import pixxaImage from '@pig-frontend/uikit/assets/images/profile-avatar/pixxa.png'
import richyImage from '@pig-frontend/uikit/assets/images/profile-avatar/richy.png'
import shinyImage from '@pig-frontend/uikit/assets/images/profile-avatar/shiny.png'
import adminImage from '@pig-frontend/uikit/assets/images/profile-avatar/admin.png'

export {
  placeholderImage,
  pixelImage,
  richyImage,
  shinyImage,
  pixxaImage,
  adminImage,
  HeadPhoneIcon,
  modalCongratImage,
  VipIcon,
}
