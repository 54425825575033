import BigNumber from 'bignumber.js'
import isEmpty from 'lodash/isEmpty'
import { VIPTag } from '@pig-frontend/uikit'
import { UserStar } from '@pig-common/components/User'
import useVIPStatus from '@pig-common/hooks/useVipStatus'
import useProfile from '@pig-common/hooks/useProfile'
import styles from './index.module.scss'
import { useEffect } from 'react'

interface IUserProfileProps {
  noDescription?: boolean
}

export default function UserProfile({ noDescription }: IUserProfileProps) {
  const { data: vipStatus } = useVIPStatus({ enabled: false })
  const { data: profile, refetch } = useProfile({ enabled: false })
  useEffect(() => {
    refetch()
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.name} data-testid="user-profile-displayname">
        {profile?.displayName}
      </div>

      <div className={styles.tier}>
        {!isEmpty(vipStatus) && (
          <>
            <UserStar progress={vipStatus?.userStar} />
            <VIPTag
              tier={vipStatus?.userTier}
              level={vipStatus?.userVipLevel}
              className={styles['vip-tag']}
            />
          </>
        )}
      </div>

      {!noDescription && vipStatus?.nextLevel && (
        <div className={styles['next-level']}>
          ทำเทิร์นโอเวอร์{' '}
          {BigNumber(vipStatus?.nextLevel?.turnover || 0).toFormat()} เพื่อรับ{' '}
          {vipStatus?.nextLevel?.vipLevel}
        </div>
      )}
    </div>
  )
}
