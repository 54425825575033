import { Fragment, useEffect } from 'react'
import { MoreOutlined, QuestionOutlined } from '@ant-design/icons'
import { useRecoilValue } from 'recoil'
import { Popover } from 'antd'
import { UserAvatar } from '@pig-common/components/User'
import { authStateCommon } from '@pig-common/recoils'
import { Button, ProfileAvatarTypes, Image } from '@pig-frontend/uikit'
import { UserShortProfileProps } from '@pig-common/types/User.type'
import withTipRainModal from '@pig-common/components/TipRain/withTipRainModal'
import useAchievementHighlight from '@pig-common/hooks/useAchievementHighlight'
import { achievementImageUrl } from '@pig-common/constants/achievement'
import styles from './index.module.scss'

const UserShortProfile = ({
  sender,
  customerCode,
  avatar,
  vip,
  onTipUser = () => {},
}: UserShortProfileProps) => {
  const isAuthentication = useRecoilValue(authStateCommon.isAuthenState)
  const {
    data: achievementHighlights,
    refetch: getAchievementHighlights,
    remove: resetAchievementHighlights,
  } = useAchievementHighlight({
    params: { customerCode },
  })

  useEffect(() => {
    getAchievementHighlights()
    return () => resetAchievementHighlights()
  }, [])

  const activeTipMenu = () => {
    onTipUser({
      customerCode: customerCode,
      receiverName: sender,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <UserAvatar avatar={avatar as ProfileAvatarTypes} vip={vip} size={72} />
      </div>
      <div className={styles.name}>{sender}</div>
      <div className={styles['customer-code']}>{customerCode}</div>

      <div className={styles.achievement}>
        {Array.from(
          { length: 3 },
          (_, index) => achievementHighlights?.[index] || null,
        )?.map((highlight) => (
          <Fragment key={highlight?.achievementUid}>
            {highlight ? (
              <Image
                src={achievementImageUrl(highlight?.achievementUid)}
                alt={highlight?.name ?? 'achievement completed'}
                width={80}
                height={80}
                className={styles.img}
              />
            ) : (
              <div className={styles.empty}>
                <QuestionOutlined className={styles.icon} />
              </div>
            )}
          </Fragment>
        ))}
      </div>

      {isAuthentication && (
        <Popover
          className={styles['action-menu']}
          placement="topRight"
          trigger={['click']}
          overlayInnerStyle={{ padding: 0 }}
          zIndex={1000} // default by antd modal
          content={
            <Button
              className={styles['tip-button']}
              type="text"
              onClick={activeTipMenu}
            >
              ทิป
            </Button>
          }
        >
          <MoreOutlined />
        </Popover>
      )}
    </div>
  )
}

export default withTipRainModal(UserShortProfile)
