import { ReactNode } from 'react'
import cx from 'classnames'
import {
  Modal as AntdModal,
  ModalProps as AntdModalProps,
  ModalFuncProps,
} from 'antd'
import { modalCongratImage } from '@pig-frontend/uikit/constants/images'
import { Image } from '@pig-frontend/uikit'
import styles from './index.module.scss'

export interface ModalProps extends AntdModalProps {
  noCancel?: boolean
  children: ReactNode
}

export function Modal({ children, noCancel, ...props }: ModalProps) {
  return (
    <AntdModal
      {...props}
      getContainer={() => document.getElementById('modal-root')}
      {...(noCancel && { footer: null })}
    >
      {children}
      {noCancel && (
        <div
          role="button"
          aria-hidden
          className={styles['no-cancel']}
          {...props.okButtonProps}
          onClick={props.onOk as () => void}
        >
          {props.okText || 'OK'}
        </div>
      )}
    </AntdModal>
  )
}

Modal.info = (props: ModalFuncProps) =>
  AntdModal.info({
    getContainer: document.getElementById('modal-root') ?? false,
    icon: null,
    okButtonProps: { className: styles['ok-button'] },
    cancelButtonProps: { className: styles['cancel-button'] },
    ...props,
    className: cx(props?.className, styles['modal-method']),
  })
Modal.error = (props: ModalFuncProps) =>
  AntdModal.error({
    getContainer: document.getElementById('modal-root') ?? false,
    icon: null,
    okButtonProps: { className: styles['ok-button'] },
    cancelButtonProps: { className: styles['cancel-button'] },
    ...props,
    className: cx(props?.className, styles['modal-method']),
  })
Modal.confirm = (props: ModalFuncProps) =>
  AntdModal.confirm({
    getContainer: document.getElementById('modal-root') ?? false,
    icon: null,
    okButtonProps: { className: styles['ok-button'] },
    cancelButtonProps: { className: styles['cancel-button'] },
    ...props,
    className: cx(props?.className, styles['modal-method']),
  })

Modal.image = (props: ModalFuncProps) =>
  Modal.info({
    className: cx([styles['modal-image'], props?.className]),
    width: 470,
    centered: true,
    ...props,
    content: null,
    title: (
      <div className={styles.image}>
        {props?.title || (
          <Image
            src={modalCongratImage}
            alt={'modal image'}
            width={200}
            height={200}
          />
        )}
        <span>{props?.content}</span>
      </div>
    ),
  })

export default Modal
