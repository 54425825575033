import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { authenticateWithSecretKeyApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'

interface IUseGenerateSecretKey {
  select?: (
    data: CommonResponse<authenticateWithSecretKeyApi.IGetGeneratedSecretKeyResponse>,
  ) => authenticateWithSecretKeyApi.IGetGeneratedSecretKeyResponse
  enabled?: boolean
}

export default function useGenerateSecretKey({
  select,
  enabled,
}: Partial<IUseGenerateSecretKey>) {
  const { cfid } = useRecoilValue(authStateCommon.authState)
  const jwt = cfid?.split(' ')?.[1]

  return useQuery({
    queryKey: [authenticateWithSecretKeyApi.key.getToken],
    queryFn: () => authenticateWithSecretKeyApi.getToken({ token: jwt }),
    enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
  })
}
