import { useQuery } from '@tanstack/react-query'
import { championApi } from '@pig-casino/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IChampionItem, ChampionParams } from '@pig-casino/types/Champion.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface ListChampionHookType<SelectReturnType> {
  params?: ChampionParams
  select?: (data: CommonResponse<IChampionItem[]>) => SelectReturnType
  key?: string[]
  enabled?: boolean
}

export default function useListChampion<SelectReturnType = IChampionItem[]>({
  params = {},
  key = [],
  select,
  enabled,
}: ListChampionHookType<SelectReturnType>) {
  return useQuery({
    queryKey: [championApi.key.list, ...key],
    queryFn: () =>
      championApi.list(
        {
          ...params,
        } || {},
      ),
    enabled,
    select: (data) => {
      return camelizeKeys(data?.data) as IChampionItem[]
    },
  })
}
