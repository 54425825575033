import { atom, selector } from 'recoil'

type RegisterState = {
  phoneNumber: string
  otpCode: string
  otpToken: string
  pin: string
  isError: boolean
  errorMessage?: string
  isReferrerValid?: boolean
}

export const key = {
  registerState: 'COMMON/REGISTER',
  registerStep: 'COMMON/REGISTER_STEP',
  registerStepHandler: 'COMMON/REGISTER_STEP_HANDLER',
}

export const defaultRegisterState: RegisterState = {
  phoneNumber: '',
  otpCode: '',
  otpToken: '',
  pin: '',
  isError: false,
  errorMessage: undefined,
  isReferrerValid: true,
}

export const registerState = atom<Partial<RegisterState>>({
  key: key.registerState,
  default: defaultRegisterState,
})

export enum RegisterStepType {
  VERIFY_OTP,
  SETUP_PIN,
  USER_INFO,
}

export const defaultRegisterStep: number = RegisterStepType.VERIFY_OTP

export const registerStepState = atom<number>({
  key: key.registerStep,
  default: 0,
})

export const setRegisterStepState = selector<number>({
  key: key.registerStepHandler,
  get: ({ get }) => get(registerStepState),
  set: ({ get, set }) => {
    const step = get(registerStepState)
    const registerData = get(registerState)

    if (
      step !== RegisterStepType.SETUP_PIN &&
      (registerData?.pin || '')?.length >= 6
    ) {
      const resultStep =
        step > RegisterStepType.VERIFY_OTP
          ? step - 1
          : RegisterStepType.VERIFY_OTP

      set(registerStepState, resultStep)
    }
  },
})
