import isBefore from 'date-fns/isBefore'
import { toJwtData } from '@pig-common/utils/jwt'

export type VerifyCfidRequest = {
  uid?: {
    name?: string
    value?: string
  }
  cfid?: {
    name?: string
    value?: string
  }
}

export enum VERIFY_CFID_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  INVALID_TOKEN = 'INVALID_TOKEN',
}

export type VerifyCfidResponse = {
  Status: VERIFY_CFID_STATUS
}

export const requestVerfiyCfid = (
  request: VerifyCfidRequest,
): VerifyCfidResponse => {
  try {
    if (!request?.cfid?.value?.startsWith('bearer')) {
      return { Status: VERIFY_CFID_STATUS.INVALID_TOKEN }
    }
    const jwtData = toJwtData(request?.cfid?.value || '')
    const expireAt = new Date(jwtData.exp)
    const isExpire = isBefore(expireAt, new Date())
    if (!isExpire)
      return {
        Status: VERIFY_CFID_STATUS.SUCCESS,
      }
    return {
      Status: VERIFY_CFID_STATUS.EXPIRED,
    }
  } catch (error) {
    return {
      Status: VERIFY_CFID_STATUS.ERROR,
    }
  }
}
